import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TextField, Select, MenuItem, Grid } from '@mui/material';
import { parseDate, parseDateHifen } from '../../../utils/parseDate';
import { intervalToDuration } from 'date-fns';
import { updateSubscription } from '../../../services/subscriptions.service';
import { getAllEvents } from "../../../services/event-type.service";


const subname = {
  "shared": "Compartilhado",
  "exclusive": "Exclusivo",
  "foodcourt": "Praça de Alimentação",
  "trailer": "Trailer",
  "bike": "Bike"
}

function getSubscriptionPrice(
  spaceType,
  dayCount = 1,
  extraChairs = 0,
  extraTables = 0
) {
  if (spaceType === "exclusive") {
    return dayCount === 1
      ? 495 + +extraChairs * 10 + +extraTables * 50
      : 795 + extraChairs * 20 + extraTables * 60;
  } else if (spaceType === "shared") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  } else if (spaceType === "foodcourt") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  } else if (spaceType === "trailer") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  } else if (spaceType === "bike") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  }
}


function getSubscriptionPriceDinamic(
  spaceType,
  dayCount = 1,
  extraChairs = 0,
  extraTables = 0,
  sharedSubPrice1,
  sharedSubPrice2,
  sharedChairPrice1,
  sharedChairPrice2,
  sharedTablePrice1,
  sharedTablePrice2,
  exclusiveSubPrice1,
  exclusiveSubPrice2,
  exclusiveChairPrice1,
  exclusiveChairPrice2,
  exclusiveTablePrice1,
  exclusiveTablePrice2,
  eventData
) {
  const d = eventData.event_data;
  if (spaceType === "exclusive") {
    return dayCount === 1
      ? exclusiveSubPrice1 +
          +extraChairs * exclusiveChairPrice1 +
          +extraTables * exclusiveTablePrice1
      : exclusiveSubPrice2 +
          extraChairs * exclusiveChairPrice2 +
          extraTables * exclusiveTablePrice2;
  } else if (spaceType === "shared") {
    return dayCount === 1
      ? sharedSubPrice1 +
          extraChairs * sharedChairPrice1 +
          extraTables * sharedTablePrice1
      : sharedSubPrice2 +
          extraChairs * sharedChairPrice2 +
          extraTables * sharedTablePrice2;
  } else if (spaceType === "foodcourt") {
    return dayCount === 1
      ? d.foodcourt.subscription_price_1_foodcourt +
          extraChairs * d.foodcourt.chair_price_foodcourt_1 +
          extraTables * d.foodcourt.table_price_foodcourt_1
      : d.foodcourt.subscription_price_2_foodcourt +
          extraChairs * d.foodcourt.chair_price_foodcourt_2 +
          extraTables * d.foodcourt.table_price_foodcourt_2;
  } else if (spaceType === "trailer") {
    console.log(spaceType, dayCount);
    return dayCount === 1
      ? d.trailer.subscription_price_1_trailer +
          extraChairs * d.trailer.chair_price_trailer_1
      : d.trailer.subscription_price_2_trailer +
          extraChairs * d.trailer.chair_price_trailer_2
  } else if (spaceType === "bike") {
    return dayCount === 1
      ? d.bike.subscription_price_1_bike +
          extraChairs * d.bike.chair_price_bike_1
      : d.bike.subscription_price_2_bike +
          extraChairs * d.bike.chair_price_bike_2
  }
}

export default function UpdateSubscriptionForm({ selectedSub, handleClose, events }) {
  const [activeStep, setActiveStep] = React.useState(0), [formData, setFormData] = React.useState({});
  console.log(selectedSub);
  const chosenevent =
    events.find((el) => el.starts_at === selectedSub.start_date || el.ends_at === selectedSub.end_date) ||
    events[0];

  React.useEffect(() => {
    setFormData((state) => ({
      ...state,
      user_id: selectedSub.user_id,
      event_id: selectedSub.event_id,
      start_date: selectedSub.start_date,
      end_date: selectedSub.end_date,
      extra_table: 0,
      extra_chair: 0
    }))
  }, [])

  const [eventTypes, setEventTypes] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setEventTypes(await getAllEvents());
    })();
  }, []);


  const steps = [
    {
      label: 'Selecionar dia/dias',
      description: <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          sx={{ my: 2 }}
          id="start_date"
          name="start_date"
          label="Data de inicio da participacao"
          type={"date"}
          onChange={(e) => { setFormData((state) => ({ ...state, start_date: e.target.value })) }}
          defaultValue={parseDateHifen(selectedSub.start_date)}
          autoComplete="start_date"
          variant="standard"
          InputProps={{inputProps: {min: parseDateHifen(chosenevent.starts_at), max: parseDateHifen(chosenevent.ends_at)}}}
          required
        />
        <TextField
          sx={{ my: 2 }}
          id="end_date"
          name="end_date"
          label="Data de Fim da participacao"
          type={"date"}
          onChange={(e) => { setFormData((state) => ({ ...state, end_date: e.target.value })) }}
          defaultValue={parseDateHifen(selectedSub.end_date)}
          autoComplete="start_date"
          variant="standard"
          InputProps={{inputProps: {min: parseDateHifen(chosenevent.starts_at), max: parseDateHifen(chosenevent.ends_at)}}}
          required
        />
      </div>,
    },
    {
      label: 'Escolher tipo de espaço do evento',
      description: <>
        <Select
          required
          labelId="event_space"
          id="event_space"
          name="event_space"
          onChange={(e) => { setFormData((state) => ({ ...state, space_type: e.target.value })) }}
          defaultValue={selectedSub.space_type}
          fullWidth
        >
          <MenuItem key={1} value={"shared"}>Espaco Compartilhado</MenuItem>
          <MenuItem key={2} value={"exclusive"}>Espaco Exclusivo</MenuItem>
        </Select>
      </>
    },
    {
      label: 'Deseja cadeira extra ao custo de R$10,00/dia?',
      description: <>
        <Select
          required
          labelId="extra_table"
          id="extra_table"
          name="extra_table"
          onChange={(e) => { setFormData((state) => ({ ...state, extra_chair: e.target.value })) }}
          defaultValue={Boolean(selectedSub.extra_chair)}
          fullWidth
        >
          <MenuItem key={1} value={true}>Sim</MenuItem>
          <MenuItem key={2} value={false}>Não</MenuItem>
        </Select>

        {(Boolean(formData.extra_chair) || Boolean(selectedSub.extra_chair)) && <TextField
          sx={{ my: 2 }}
          id="chair_quantity"
          name="chair_quantity"
          label="Quantas cadeiras?"
          onChange={(e) => { setFormData((state) => ({ ...state, chair_quantity: +e.target.value })) }}
          variant="standard"
          defaultValue={selectedSub.chair_quantity}
          fullWidth
        />}
      </>,
    },
    {
      label:`Deseja mesa?
      Mesa Espaço Maior: 1,70m x 0,70 -
      Mesa Espaço Menor/Compartilhado: 0,85m x 0,70.       
       `,
      description: <>
        <Select
          required
          labelId="extra_table"
          id="extra_table"
          name="extra_table"
          onChange={(e) => { setFormData((state) => ({ ...state, extra_table: e.target.value })) }}
          defaultValue={Boolean(selectedSub.extra_table)}
          fullWidth
        >
          <MenuItem key={1} value={true}>Sim</MenuItem>
          <MenuItem key={2} value={false}>Não</MenuItem>
        </Select>

        {(Boolean(formData.extra_table) || Boolean(selectedSub.extra_table)) && <TextField
          sx={{ my: 2 }}
          id="table_quantity"
          name="table_quantity"
          label="Quantas mesas?"
          onChange={(e) => {
            setFormData((state) => ({ ...state, table_quantity: +e.target.value }))
          }}
          defaultValue={selectedSub.table_quantity}
          variant="standard"
          fullWidth
        />}
      </>,
    },
    {
      label: 'Descreva TODOS os itens que você pretende levar para venda na Feira do Bem (lembrando que só são permitidos itens autorais - não é autorizado a revenda e que a Curadoria pode aprovar parcialmente os itens descritos por você abaixo)',
      description: <>
        <TextField
          sx={{ my: 2 }}
          id="items"
          name="items"
          label="Itens"
          onChange={(e) => { setFormData((state) => ({ ...state, items: e.target.value })) }}
          autoComplete="items"
          variant="standard"
          rows={4}
          multiline
          defaultValue={selectedSub.items}
          fullWidth
          required
        />
      </>,
    },
    {
      label: 'Alguma observação?',
      description: <>
        <TextField
          sx={{ my: 2 }}
          id="notes"
          name="notes"
          label="Notas"
          onChange={(e) => { setFormData((state) => ({ ...state, notes: e.target.value })) }}
          autoComplete="notes"
          variant="standard"
          defaultValue={selectedSub.notes}
          rows={4}
          multiline
          fullWidth
          required
        />
      </>,
    },
  ];

  const handleNext = (e) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (formData.space_type && formData.start_date && formData.end_date) {
      const data = eventTypes.find(
        (evente) => +evente.id === +chosenevent.event_data.eventType_id
      );
      console.log(formData);
      const amount = chosenevent.event_data
        ? getSubscriptionPriceDinamic(
            formData.space_type,
            intervalToDuration({
              start: new Date(formData.start_date),
              end: new Date(formData.end_date),
            }).days + 1,
            formData.extra_chair ? formData.chair_quantity : 0,
            formData.extra_table ? formData.table_quantity : 0,
            data.event_data.shared.subscription_price_1_shared,
            data.event_data.shared.subscription_price_2_shared,
            data.event_data.shared.chair_price_shared_1,
            data.event_data.shared.chair_price_shared_2,
            data.event_data.shared.table_price_shared_1,
            data.event_data.shared.table_price_shared_2,
            data.event_data.exclusive.subscription_price_1_exclusive,
            data.event_data.exclusive.subscription_price_2_exclusive,
            data.event_data.exclusive.chair_price_exclusive_1,
            data.event_data.exclusive.chair_price_exclusive_2,
            data.event_data.exclusive.table_price_exclusive_1,
            data.event_data.exclusive.table_price_exclusive_2,
            data
          )
        : getSubscriptionPrice(
            formData.space_type,
            intervalToDuration({
              start: new Date(formData.start_date),
              end: new Date(formData.end_date),
            }).days + 1,
            formData.chair_quantity,
            formData.table_quantity
          );
      setFormData((state) => ({ ...state, amount: amount }));
    }
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = async () => {
    await updateSubscription(formData, selectedSub.id)
  }

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Voltar
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography my={2}>
            Confirme suas escolhas para criar sua pré-inscrição:
          </Typography>
          <Grid>
            <Typography>
              <span style={{ color: "#a8518e" }}>
                Data de início da sua participação:{" "}
              </span>
              {parseDate(formData.start_date)}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>
                Data de fim da sua participação:{" "}
              </span>
              {parseDate(formData.end_date)}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Tipo de espaço: </span>
              {subname[formData.space_type]}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Mesa: </span>
              {formData.extra_table ? "Sim" : "Não"}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Quantas mesas: </span>
              {formData.table_quantity || 0}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Cadeira Extra: </span>
              {formData.extra_chair ? "Sim" : "Não"}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Quantas cadeiras: </span>
              {formData.chair_quantity || 0}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Evento: </span>
              {chosenevent.name}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Itens: </span>
              {formData.items}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Observação: </span>
              {formData.notes}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Valor: </span> R${" "}
              {formData.amount},00
            </Typography>
            <Typography></Typography>
          </Grid>


          <Grid flex>
            <Button variant='outlined' onClick={() => { handleClose() }} sx={{ mt: 1, mr: 1 }}>
              Cancelar
            </Button>

            <Button variant='contained'onClick={() => {
              
                handleSubmit().then(() => {
                  alert("Pré-inscrição alterada com sucesso!")
                  handleClose();
                  window.location.reload();
                })
              }} sx={{ mt: 1, mr: 1 }}>
              Alterar Pré-inscrição
            </Button>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}