import React from "react";
import { getUserOnly } from "../../../services/expositor/subscriptions.service";
import { Grid, Paper, Typography } from "@mui/material";
import SectionTitle from "../../../ui-components/SectionTitle";
import { Button } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { parseDateLocale } from "../../../utils/parseDate";
import { deleteSubscription } from "../../../services/subscriptions.service";

const space = {
  1: "Compartilhado",
  2: "Exclusivo",
};

const tablechair = {
  1: "Sem mesa/cadeira extra",
  2: "Mesa extra",
  3: "Cadeira extra",
  4: "Mesa/cadeira extra",
};

export default function PreSubscriptions() {
  const [userSubscriptions, setUserSubscriptions] = React.useState([]);

  async function syncSubscriptions() {
    setUserSubscriptions(await getUserOnly());
  }

  React.useEffect(() => {
    syncSubscriptions();
  }, []);

  return (
    <>
      <Grid container>
        <Paper sx={{ p: 2, width: "100%" }}>
          <SectionTitle title={"Inscrições aprovadas ✅:"} />
          {userSubscriptions &&
            userSubscriptions
              .filter((x) => x.status_id === 1)
              .map((sub) => (
                <Grid pb="10px" key={sub.id}>
                  <Grid>
                    <Paper
                      sx={{
                        borderLeft: sub.is_paid
                          ? "6px solid green"
                          : "6px solid red",
                        p: "8px 10px",
                      }}
                    >
                      <Grid
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Grid>
                          <Typography variant="h5">
                            Evento: Feira do Bem
                          </Typography>
                          <Typography variant="h5" sx={{ color: "green" }}>
                            Valor Total da Inscrição: ${sub.amount},00
                          </Typography>
                          <Typography>
                            Pagamento confirmado pelo evento:{" "}
                            {sub.is_paid ? "Sim" : "Não"}
                          </Typography>
                          <Typography>
                            Seu número no mapa do evento: {sub.event_space ? sub.event_space : "Ainda não foi adicionado, aguarde."}
                          </Typography>

                          <Typography my={2}>Suas Escolhas:</Typography>

                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Data de início: {" "}
                            </span>
                            {parseDateLocale(sub.start_date)}
                          </Typography>
                          <Typography>
                          <span style={{ color: "#a8518e" }}>
                              Data de fim: {" "}
                            </span> 
                            {parseDateLocale(sub.end_date)}
                          </Typography>
                          <Typography>
                          <span style={{ color: "#a8518e" }}>
                              Tipo de espaço: {" "}
                            </span>
                            {space[sub.space_type]}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Mesa extra:{" "}
                            </span>
                            {sub.extra_table ? "Sim" : "Não"}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Quantas mesas:{" "}
                            </span>
                            {sub.table_quantity || 0}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Cadeira Extra:{" "}
                            </span>
                            {sub.extra_chair ? "Sim" : "Não"}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Quantas cadeiras:{" "}
                            </span>
                            {sub.chair_quantity || 0}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>Itens: </span>
                            {sub.items}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Observação:{" "}
                            </span>
                            {sub.notes}
                          </Typography>



                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "8px",
                              background: "gray",
                              borderRadius: "10px",
                              textAlign: "center",
                            }}
                          >
                            Última atualização:{" "}
                            {parseDateLocale(sub.updated_at)}
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              const s = document.getElementById(
                                "sub" + sub.id
                              ).style.display;
                              document.getElementById(
                                "sub" + sub.id
                              ).style.display = s === "none" ? "block" : "none";
                            }}
                            variant="contained"
                          >
                            Pagar Inscrição
                          </Button>
                          {/* <Button variant="contained">
                            Alterar Informações
                          </Button> */}
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Você realmente deseja cancelar esta inscrição já aprovada? Essa operação é irreversível!"
                                )
                              ) {
                                deleteSubscription(sub.id).then(() => {
                                  alert("Inscrição apagada com sucesso!");
                                  window.location.reload();
                                });
                              } else {
                                console.log("cancelado");
                              }
                            }}
                            variant="outlined"
                          >
                            Apagar Inscrição
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <div
                    id={"sub" + sub.id}
                    style={{ display: "none" }}
                  >
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 4,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "20px",
                        }}
                      >
                        <img
                          style={{ marginBottom: "12px" }}
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg/2560px-Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg.png"
                          alt=""
                          width={300}
                          height={100}
                        />
                        <QRCodeSVG value={sub.pix_code} size={300} />
                      </div>
                      <div>
                        <h3>Instruções para pagamento PIX</h3>

                        <p>
                          1. Acesse o app do banco que você deseja usar para
                          pagar; <br />
                          2. Procure a opção de pagamento por Pix, toque em
                          Pix/QR Code; <br />
                          3. Selecione a opção Pagar com QR Code e posicione o
                          aparelho para leitura do QR Code; <br />
                          4. Verifique os dados e clique em Confirmar Pagamento.
                        </p>
                      </div>
                    </Paper>
                  </div>
                </Grid>
              ))}
        </Paper>
      </Grid>
    </>
  );
}
