import React from "react";
import { IconButton, Link } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAdminStore } from "../../../../../context/admin-store";

function parseDate(date: Date) {
    const options: any = { weekday: "long", month: "long", day: "numeric", year: "numeric" };
    return new Date(date).toLocaleDateString("pt-BR", options);
  }

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Expositor {
  name: string;
  email: string;
  created_at: Date;
  is_admin: boolean;
  full_address: string;
  instagram_url: string;
  brand_name: string;
  address: string;
  id: number;
  phone: string;
  brand_description: string;
  state: string;
  city: string;
}

export default function ViewButton({
  id,
  updateEvents,
  expositor,
}: {
  id: number;
  updateEvents: Function;
  expositor: Expositor;
}) {
  const bios = useAdminStore((state) => state.bios);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Vendo todas as informações do Expositor:
          </Typography>
          <>
            <p>ID: {expositor.id}</p>
            <p>Nome: {expositor.name}</p>
            <p>Email: <Link href={`mailto:${expositor.email}`}> {expositor.email}</Link></p>
            <p>Instagram: <Link target={"_blank"} rel={"noopener noreferrer"} href={`https://www.instagram.com/${expositor.instagram_url.split("@")[1]}/`}>{expositor.instagram_url}</Link></p>
            <p>Telefone: {expositor.phone}</p>
            <p>Endereço: {expositor.address}</p>
            <p>Estado: {expositor.state}</p>
            <p>Cidade: {expositor.city}</p>

            {bios.length > 0 && <p>Bio: {(bios.find((bio) => bio.user_id === expositor.id))?.bio}</p>}

            <p>Nome da Marca: {expositor.brand_name}</p>
            <p>Descrição: {expositor.brand_description}</p>
            <p>Criado em: {parseDate(expositor.created_at)}</p>
          </>
        </Box>
      </Modal>
      <IconButton title="Pressione 2 vezes para ver mais" onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
    </>
  );
}
