import { getToken } from "./auth.service"

const getAllCategories = async () => {
  return await (await fetch(`${process.env.REACT_APP_API_URL}/categories`)).json()
}

const createCategory = async (data: any) => {  
  return await (await fetch(`${process.env.REACT_APP_API_URL}/categories`, {
    method: "POST",
    headers: {
      "Content-Type" : "application/json",
      "Authorization": `Bearer ${getToken()}`
      
    },
    body: JSON.stringify(data)
  })).json()
}

const deleteCategory = async (id: number) => {  
  return await (await fetch(`${process.env.REACT_APP_API_URL}/categories/${id}`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${getToken()}`
    }
  })).json()
}

export {getAllCategories, createCategory, deleteCategory}