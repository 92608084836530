import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select } from "@mui/material";
import { logout } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { ModalComponent, useModal } from "../../ui-components/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  getExpositorInfo,
  updateExpositorInfo,
  updatePassword,
} from "../../services/expositor.service";
import { getAllCategories } from "../../services/category.service";
import { parseDateHifen } from "../../utils/parseDate";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userData, setUserData] = React.useState<any>({});
  const [categories, setCategories] = useState([]);
  const [inEditMode, setEditMode] = useState(true);
  const [age, setAge] = useState("");

  useEffect(() => {
    getAllCategories().then((x) =>
      setCategories(x.sort((a, b) => a.name.localeCompare(b.name)))
    );
  }, []);

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { open: modalOpen, handleClose: modalClose, handleOpen } = useModal();
  const {
    open: passOpen,
    handleClose: handlePassClose,
    handleOpen: handlePassOpen,
  } = useModal();

  const navigate = useNavigate();

  React.useEffect(() => {
    getExpositorInfo().then((x) => {
      setUserData(x.user);
      setAge(x.user.category_id);
    });
  }, []);

  const handleLogout = () => {
    logout();
    localStorage.removeItem("userInfo");
    navigate("/", { replace: true });
  };

  function handleDataSubmit(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    const items = Object.fromEntries(formData.entries());
    console.log(items);

    updateExpositorInfo(items).then((x) => {
      if (x.status === 200) {
        window.localStorage.setItem("userInfo", JSON.stringify({...userInfo, name: items.name}))
        window.location.reload();
      } else {
        alert("Ocorreu algum erro ao alterar seus dados, tente novamente!");
        modalClose();
      }
    });
  }

  function handlePasswordSubmit(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    const items = Object.fromEntries(formData.entries());

    if (items.password !== items.confirm_password) {
      alert("As senhas são diferentes, tente novamente")
    } else {
      updatePassword(items.password).then((x) => {
        if (x.status === 200) {
          alert("Senha alterada com sucesso!")
          handlePassClose()
        } else {
          alert("Ocorreu algum erro ao alterar seus dados, tente novamente!");
          modalClose();
        }
      });
    }

    
  }

  const userInfo = JSON.parse(localStorage.getItem("userInfo")!);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Typography sx={{ minWidth: 100 }}>{userInfo.name}</Typography>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 1 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {userInfo.name[0].toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleOpen}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Perfil
        </MenuItem>

        <MenuItem onClick={handlePassOpen}>
          <ListItemIcon>
            <KeyIcon fontSize="small" />
          </ListItemIcon>
          Alterar senha
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Desconectar
        </MenuItem>
      </Menu>
      <ModalComponent open={passOpen} handleClose={handlePassClose}>
        <form onSubmit={handlePasswordSubmit}>
          <TextField
            required
            id="senha"
            name="password"
            label="Digite sua Nova Senha"
            fullWidth
            autoComplete="password"
            variant="standard"
            type={"password"}
            InputProps={{ inputProps: { minLength: 6 } }}
          />

          <TextField
            required
            id="senha"
            name="confirm_password"
            label="Digite Novamente a nova senha para confirmar"
            fullWidth
            autoComplete="password"
            variant="standard"
            type={"password"}
            InputProps={{ inputProps: { minLength: 6 } }}
          />
          <Grid >
          <Button onClick={handlePassClose} variant="outlined" sx={{mt: "30px", mr: "30px"}}>Cancelar</Button>
          <Button variant="contained" sx={{mt: "30px"}} type="submit">Alterar senha</Button>
          </Grid>
        </form>
      </ModalComponent>
      <ModalComponent open={modalOpen} handleClose={modalClose}>
        <Grid display={"flex"} justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            Seus Dados Cadastrados
          </Typography>
          <Button onClick={() => setEditMode(!inEditMode)} variant="contained">
            {inEditMode ? "Editar Meus Dados" : "Cancelar Edição"}
            <EditIcon />
          </Button>
        </Grid>

        <form onSubmit={handleDataSubmit} action="">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Nome"
                fullWidth
                autoComplete="given-name"
                variant="standard"
                defaultValue={userData.name}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="birthday"
                name="birthday"
                label="Data de Nascimento"
                type={"date"}
                fullWidth
                defaultValue={parseDateHifen(userData.birthday)}
                autoComplete="birthday"
                variant="standard"
                required
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="Cidade"
                fullWidth
                autoComplete="shipping address-level2"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                defaultValue={userData.city}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="state"
                name="state"
                label="Estado"
                fullWidth
                variant="standard"
                type={"text"}
                InputProps={{ inputProps: { minLength: 2, maxLength: 2 } }}
                InputLabelProps={{ shrink: true }}
                defaultValue={userData.state}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="zipcode"
                name="zipcode"
                label="CEP"
                fullWidth
                InputProps={{ inputProps: { minLength: 8, maxLength: 8 } }}
                autoComplete="shipping postal-code"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                defaultValue={userData.zipcode}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="district"
                name="district"
                label="Bairro"
                fullWidth
                autoComplete="shipping district"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                defaultValue={userData.district}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="address"
                name="address"
                label="Rua"
                fullWidth
                autoComplete="shipping street"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                defaultValue={userData.address}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="address_number"
                name="address_number"
                label="Número"
                fullWidth
                autoComplete="shipping address_number"
                variant="standard"
                defaultValue={userData.address_number}
                disabled={inEditMode}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address_complement"
                name="address_complement"
                label="Complemento"
                autoComplete="address_complement"
                variant="standard"
                fullWidth
                defaultValue={userData.address_complement}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="phone"
                name="phone"
                label="Telefone"
                autoComplete="phone"
                variant="standard"
                fullWidth
                defaultValue={userData.phone}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="cpf"
                name="cpf"
                label="CPF"
                autoComplete="cpf"
                variant="standard"
                type={"number"}
                fullWidth
                defaultValue={userData.cpf}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                autoComplete="cnpj"
                variant="standard"
                type={"number"}
                fullWidth
                defaultValue={userData.cnpj}
                disabled={inEditMode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id="brand_name"
                name="brand_name"
                label="Nome da Marca"
                fullWidth
                autoComplete="brand_name"
                variant="standard"
                defaultValue={userData.brand_name}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="instagram_url"
                name="instagram_url"
                label="Instagram"
                fullWidth
                autoComplete="instagram_url"
                variant="standard"
                defaultValue={userData.instagram_url}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="brand_description"
                name="brand_description"
                label="Descrição da Marca"
                rows={4}
                multiline
                fullWidth
                placeholder="Descreva sua marca aqui"
                variant="standard"
                defaultValue={userData.brand_description}
                disabled={inEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="brand_items"
                label="Itens da sua marca"
                name="brand_items"
                rows={3}
                multiline
                fullWidth
                placeholder="Descreva os itens que sua marca possui"
                variant="standard"
                defaultValue={userData.brand_items}
                disabled={inEditMode}
              />
              <Grid item xs={12}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Categoria da Marca
                  </InputLabel>
                  <Select
                    required
                    labelId="category_id"
                    id="category_id"
                    name="category_id"
                    fullWidth
                    value={age}
                    onChange={handleChange}
                    disabled={inEditMode}
                  >
                    {categories &&
                      categories
                        .filter((x) => x.active)
                        .map((x) => (
                          <MenuItem key={x.id} value={x.id}>
                            {x.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              {!inEditMode && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: "30px" }}
                  fullWidth
                >
                  Alterar dados da minha conta
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </React.Fragment>
  );
}
