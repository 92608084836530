
const API_URL = process.env.REACT_APP_API_URL;

export function signupApiRequest(data: any) {
  return fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

