import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid, Paper, Link, Button } from "@mui/material";
import SectionTitle from "../../../../ui-components/SectionTitle";
import {
  getAllSubscriptions,
  getSubscriptionStatus,
} from "../../../../services/subscriptions.service";
import UpdateButton from "./OperationButtons/UpdateButton";
import { parseDate } from "../../../../utils/parseDate";
import { useAdminStore } from "../../../../context/admin-store";
import { useCategory } from "../../../../context/category-store";
import ViewButton from "./OperationButtons/ViewButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as XLSX from "xlsx"

const columns: GridColDef[] = [
  { field: "event_space", headerName: "EC", width: 40 },
  { field: "name", headerName: "Nome Usuário", width: 120, resizable: true },
  { field: "brand_name", headerName: "Marca", width: 140 },
  {
    field: "instagram_url",
    headerName: "Instagram",
    width: 110,
    renderCell: (param) => <>{param.value}</>,
  },
  { field: "category", headerName: "Categoria", width: 120 },
  { field: "is_paid", headerName: "Pago", width: 80 },
  { field: "space_type", headerName: "Tipo de Espaço", width: 140 },
  { field: "extra_table", headerName: "Cadeira", width: 80 },
  { field: "extra_chair", headerName: "Mesa", width: 80 },
  { field: "chair_quantity", headerName: "Cad Q.", width: 80 },
  { field: "table_quantity", headerName: "Mes Q.", width: 80 },
  { field: "start_date", headerName: "Data Entrada", width: 110 },
  { field: "end_date", headerName: "Data Saida", width: 110 },
  {
    field: "actions",
    headerName: "Ações",
    width: 100,
    renderCell: (param) => <>{param.value}</>,
  },
];
interface PreSubscription {
  id: number;
  user_id: number;
  event_id: number;
  first_time: boolean;
  start_date: Date;
  end_date: Date;
  status_id: number;
  extra_chair: number;
  extra_table: number;
  table_quantity: number;
  chair_quantity: number;
  created_at: Date;
  is_paid: boolean;
  event_space: number;
  space_type: string;
}

const createRows = (
  events: PreSubscription[],
  updateSubscriptions: Function,
  users: any,
  categories: any
) => {
  return events.map(
    (
      {
        event_id,
        created_at,
        user_id,
        event_space,
        start_date,
        id,
        end_date,
        status_id,
        is_paid,
        extra_chair,
        extra_table,
        chair_quantity,
        table_quantity,
        space_type
      },
      i,
      arr
    ) => {
      const user = users.find((user) => user.id === user_id);
      const category = categories.find((cat) => cat.id === user.category_id);
      return {
        id,
        event_id,
        name: user.name,
        event_space,
        brand_name: user.brand_name,
        created_at: parseDate(created_at),
        instagram_url: (
          <Link
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={`https://www.instagram.com/${user.instagram_url.split("@")[1]
              }/`}
          >
            {user.instagram_url}
          </Link>
        ),
        category: category.name,
        user_id,
        extra_chair: extra_chair ? "Sim" : "Não",
        extra_table: extra_table ? "Sim" : "Não",
        table_quantity,
        chair_quantity,
        is_paid: is_paid ? "Sim" : "Não",
        space_type: space_type === "exclusive" ? "Exclusivo" : "Compartilhado",
        start_date: parseDate(start_date),
        end_date: parseDate(end_date),
        status_id: getSubscriptionStatus(status_id),
        actions: (
          <>
            <UpdateButton
              id={id}
              updateSubscriptions={updateSubscriptions}
              sub={arr[i]}
            />
            <ViewButton
              id={id}
              updateEvents={() => { }}
              subscription={arr[i]}
            ></ViewButton>
          </>
        ),
      };
    }
  );
};

export default function SubscriptionTable() {
  const [subscriptions, setSubscriptions] = useState([]);
  const users = useAdminStore((state) => state.users);
  const categories = useCategory((state) => state.categories);
  const events = useAdminStore((state) => state.events);

  function getSubscriptions() {
    getAllSubscriptions().then((x) =>
      setSubscriptions(x.filter((x: any) => x.status_id === 1))
    );
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  function exportData() {
    const excelsubs = subscriptions.map((obj) => ({ ...obj, event_name: (events.find(ev => ev.id === obj.event_id))?.name || "EVENTO APAGADO", user_name: (users.find(ev => ev.id === obj.user_id))?.name, extra_table: +obj.extra_table ? "Sim" : "Nao", extra_chair: +obj.extra_chair ? "Sim" : "Nao" }))
    const worksheet = XLSX.utils.json_to_sheet(excelsubs)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inscricoes");
    XLSX.utils.sheet_add_aoa(worksheet, [["ID", "Usuario ID", "Evento ID", "Notas", "Tipo Espaco", "Items", "Categoria ID", "Espaco Evento", "Data Inicio", "Data Fim", "Mesa Extra", "Status ID", "Pagamento Efetuado", "Codigo PIX", "Cadeira Extra", "Valor", "Quantidade Mesas", "Quantidade Cadeiras", "Criado Em", "Atualizado Em", "Nome Evento", "Nome Usuario"]], { origin: "A1" });
    XLSX.writeFile(workbook, "Inscricoes.xlsx", { compression: true });
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <SectionTitle title="Inscrições" />
        <Button variant="contained" onClick={exportData}>EXPORTAR DADOS COMO EXCEL</Button>
      </div>
      {events.map((event) => (
        <Paper sx={{ p: 1 }}>
          <Accordion key={event.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{event.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container my={3}>
                <Grid item xs={12}>
                  <div style={{ height: 660, width: "100%", marginBottom: 50 }}>
                    <p style={{ margin: 0 }}>Total de Cadeiras deste evento: {subscriptions.filter((sub) => sub.event_id === event.id).reduce((x, subs) => x += subs.chair_quantity, 0)}</p>
                    <p style={{ margin: 0 }}>Total de Mesas deste evento: {subscriptions.filter((sub) => sub.event_id === event.id).reduce((x, subs) => x += subs.table_quantity, 0)}</p>
                    <DataGrid
                      rows={createRows(
                        subscriptions.filter(
                          (sub) => sub.event_id === event.id
                        ),
                        getSubscriptions,
                        users,
                        categories
                      )}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      checkboxSelection={false}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
      ))}
    </>
  );
}
