import { getToken } from "./auth.service";

const getAll = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/commissions`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "GET",
    }).then(x => x.json())
}

const getAllCommissions = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/commissions/user`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "GET",
    }).then(x => x.json())
}

const updateCommission = (data: any, id: number) => {
    return fetch(`${process.env.REACT_APP_API_URL}/commissions/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "PATCH",
        body: JSON.stringify(data)
    }).then((x) => x.json())
}

const createCommmission = async (data: any) => {
    console.log(JSON.stringify(data));
    
    return (await fetch(`${process.env.REACT_APP_API_URL}/commissions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })).json()
}

function deleteCommission(id: number){
    return fetch(`${process.env.REACT_APP_API_URL}/commissions/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "DELETE",
    })
}
export { getAllCommissions, updateCommission, createCommmission, getAll, deleteCommission }