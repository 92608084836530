import React from "react";
import { IconButton, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { updateEvent } from "../../../../../services/event.service";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CurrencyInput from "react-currency-input-field";
import { useAdminStore } from "../../../../../context/admin-store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Event {
  active: boolean;
  cover: string;
  created_at: Date;
  description: string;
  ends_at: Date;
  name: string;
  updated_at: Date;
  starts_at: Date;
  id: number;
  limitdate: Date;
  event_data: any;
  event_address: string;
}

export default function DeleteButton({ id, updateEvents, event }: { id: number, updateEvents: Function, event: Event }) {

  const [open, setOpen] = React.useState(false);
  const eventTypes = useAdminStore(state => state.eventTypes)

  console.log(event);
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData: any = Object.fromEntries(new FormData(e.target).entries())

    const reqObj = {
      name: formData.name, active: formData.active === "true", description: formData.description, starts_at: formData.starts_at, ends_at: formData.ends_at, limitdate: formData.limitdate, event_address: formData.event_address, event_data: JSON.stringify({
        eventType_id: formData.eventType_id
      })
    }
    updateEvent(reqObj, id).then(() => {
      updateEvents()
    })
    handleClose()
  }

  const { starts_at, ends_at, limitdate, event_data } = event;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editando as informações do Evento :
          </Typography>

          <form onSubmit={handleSubmit} style={{ overflowY: "scroll", maxHeight: "80vh" }}>
            <p>Nome: <input name="name" type="text" defaultValue={event.name} /></p>
            <p>Descrição: <input name="description" type="text" defaultValue={event.description} /></p>
            <p>Endereço do Evento: <input name="event_address" type="text" defaultValue={event.event_address} /></p>
            <p>Evento ativo:
              <select name="active" defaultValue={event.active.toString()}>
                <option value={"true"}>Sim</option>
                <option value={"false"}>Não</option>
              </select></p>
            <p>Data de início: <input name="starts_at" type="date" defaultValue={new Date(starts_at).toISOString().split('T')[0]} /></p>
            <p>Data de Término: <input name="ends_at" type="date" defaultValue={new Date(ends_at).toISOString().split('T')[0]} /></p>
            <p>Data de limite para pagamento: <input name="limitdate" type="date" defaultValue={new Date(limitdate).toISOString().split('T')[0]} /></p>
            <p>Tipo de Evento: <select name="eventType_id" defaultValue={event_data?.eventType_id || 0}>
                {eventTypes.map((type) => (
                  <option value={type.id}>{type.event_data.name}</option>
                ))}
              </select></p>
            

            <Button onClick={handleClose}>Cancelar</Button> <Button type="submit">Alterar</Button>
          </form>
        </Box>
      </Modal>
      <IconButton
        onClick={() => {
          handleOpen();
          updateEvents();
        }}
      >
        <EditIcon />
      </IconButton>
    </>
  );
}
