const TOKEN_KEY = '@feiradobem/token';
const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
const getToken = () => localStorage.getItem(TOKEN_KEY);
const login = (token: string) => localStorage.setItem(TOKEN_KEY, token);
const logout = () => localStorage.removeItem(TOKEN_KEY);
const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'))

const isAdminRequest = async () => {
    const adminReq = await (await fetch(`${process.env.REACT_APP_API_URL}/secure-admin`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })).json()

    return adminReq.message === "You are an admin."
}

export { isAuthenticated, getToken, login, logout, isAdminRequest, getUserInfo };