import { createTheme } from "@mui/material/styles";

function createCustomTheme(mode: "light" | "dark"){
    return createTheme({
        palette: {
            mode,
            primary: {
                main: '#a8518e',
                dark: '#fffcff',
                contrastText: '#ffd9f3',
                light: '#ffd7f3',
            },
            secondary: {
                main: '#c51162',
            },
        },
    })
}

export default createCustomTheme;