import React from "react";
import { IconButton, Link } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { parseDateLocale } from "../../../../../utils/parseDate";

const space = {
  shared: "Compartilhado",
  exclusive: "Exclusivo",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ViewButton({
  id,
  updateEvents,
  subscription,
}: {
  id: number;
  updateEvents: Function;
  subscription: any;
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Vendo todas as informações da Pre-inscrição:
          </Typography>
          <>
            <Typography>
              Pagamento confirmado pelo evento: {subscription.is_paid ? "Sim" : "Não"}
            </Typography>
            <Typography>
              Valor a ser pago: {subscription.is_paid ? "Sim" : "Não"}
            </Typography>

            <Typography my={2}>Suas Escolhas:</Typography>

            <Typography>
              <span style={{ color: "#a8518e" }}>Data de início: </span>
              {parseDateLocale(subscription.start_date)}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Data de fim: </span>
              {parseDateLocale(subscription.end_date)}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Tipo de espaço: </span>
              {space[subscription.space_type]}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Mesa extra: </span>
              {subscription.extra_table ? "Sim" : "Não"}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Quantas mesas: </span>
              {subscription.table_quantity || 0}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Cadeira Extra: </span>
              {subscription.extra_chair ? "Sim" : "Não"}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Quantas cadeiras: </span>
              {subscription.chair_quantity || 0}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Itens: </span>
              {subscription.items}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Observação: </span>
              {subscription.notes}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Valor: </span> R$ {subscription.amount}
              ,00
            </Typography>
          </>
        </Box>
      </Modal>
      <IconButton title="Pressione 2 vezes para ver mais" onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
    </>
  );
}
