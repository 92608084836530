import create from "zustand";
import { getAllExpositors } from "../services/expositor.service";
import { getAllEvents } from "../services/event.service";
import { getAllEvents as getAllEvents2 } from "../services/event-type.service";
import { getAllSubscriptions } from "../services/subscriptions.service";
import { getAllBios } from "../services/bio.service";

export const useAdminStore = create(set =>( {
  users: [],
  events: [],
  subscriptions: [],
  bios: [],
  eventTypes: [],
  fetch: async () => {
    const users = (await (await getAllExpositors()).json());
    const events = await getAllEvents();
    const subscriptions = (await getAllSubscriptions());
    const bios = (await getAllBios());
    const eventTypes = (await getAllEvents2())
    
    set({
      users: users,
      events: events,
      subscriptions: subscriptions,
      bios: bios,
      eventTypes
    })
  }
}))