import { SAVE_SIGNUP_DATA, MAKE_SIGNUP_REQUEST } from "../actions/types";
import { signupApiRequest } from "../../services/signup.service";

export default function signupReducer(state: any = {} , action: any){
    switch (action.type) {
        case SAVE_SIGNUP_DATA:
            const data = action.payload;
            return {...state, ...data};
        case MAKE_SIGNUP_REQUEST: 
            signupApiRequest(state)
                .then(async (x) => {
                    if(x.status !== 200) {
                        (<any>window).signup = "error";
                        (<any>window).signupMessage = await x.json()
                    }})    
            return state
        default:
            return state;
    }
};

