import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { updateSubscription } from "../../../../../services/subscriptions.service";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface PreSubscription {
  id: number;
  user_id: number;
  event_id: number;
  first_time: boolean;
  start_date: Date;
  end_date: Date;
  status_id: number;
  created_at: Date;
  is_paid: boolean;
  event_space: number;
}

export default function DeleteButton({ id, updateSubscriptions, sub }: { id: number, updateSubscriptions: Function, sub: PreSubscription }) {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData: any = Object.fromEntries(new FormData(e.target).entries());
    formData.is_paid = formData.is_paid === "true";
    formData.status_id = parseInt(formData.status_id);
    updateSubscription(formData, id).then(() => {
      alert("Alterado com sucesso!")
      updateSubscriptions();
    })
    handleClose();
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editando as informações da Inscrição :
          </Typography>

          <form onSubmit={handleSubmit}>
            <p>Pagamento efetuado :
              <select name="is_paid" defaultValue={sub.is_paid.toString()}>
                <option value={"true"}>Sim</option>
                <option value={"false"}>Não</option>
                <option value={"true"}>Checar</option>
              </select></p>

            <p>Alterar status da Pre Inscrição:
              <select name="status_id" defaultValue={sub.status_id}>
                <option value={0}>Em Análise</option>
                <option value={1}>Aceito</option>
                <option value={2}>Recusado</option>
              </select></p>

              <p>Alterar ou adicionar posição no Croqui:
               <input type="number" name="event_space" defaultValue={sub.event_space} /></p>
            <Button onClick={handleClose}>Cancelar</Button> <Button type="submit">Alterar</Button>
          </form>
        </Box>
      </Modal>
      <IconButton
        onClick={() => {
          handleOpen();
          updateSubscriptions();
        }}
      >
        <EditIcon />
      </IconButton>
    </>
  );
}
