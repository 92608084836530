import { Typography } from '@mui/material';
import React from 'react'

interface SectionTitleProps {
    title: string;
}

export default function SectionTitle(props: any) {
  const {title} = props;
  return (
    <Typography {...props} component="h2" variant="h6" color="primary" gutterBottom>
        {title}
    </Typography>
  )
}
