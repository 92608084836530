import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid, Paper, Link, Button } from "@mui/material";
import SectionTitle from "../../../../ui-components/SectionTitle";
import { getAll } from "../../../../services/commissions.service";
import { parseDate } from "../../../../utils/parseDate";
import { useAdminStore } from "../../../../context/admin-store";
import { useCategory } from "../../../../context/category-store";
import UpdateButton from "./OperationButtons/UpdateButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as XLSX from "xlsx"

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 40 },
  { field: "name", headerName: "Dono", width: 120 },
  { field: "brand_name", headerName: "Marca", width: 100 },
  {
    field: "instagram_url",
    headerName: "Instagram",
    width: 110,
    renderCell: (param) => <>{param.value}</>,
  },
  { field: "category", headerName: "Categoria", width: 140 },
  { field: "amount", headerName: "Valor", width: 100 },
  { field: "is_paid", headerName: "Pago", width: 80 },
  { field: "date", headerName: "Data Declarada", width: 120 },
  {
    field: "actions",
    headerName: "Ações",
    width: 100,
    renderCell: (param) => <>{param.value}</>,
  },
];

const createRows = (
  events: any,
  updateSubscriptions: Function,
  users: any,
  categories: any
) => {
  return events.map(
    ({ event_id, user_id, start_date, id, date, is_paid, amount }, i, arr) => {
      const user = users.find((user) => user.id === user_id);
      const category = categories.find((cat) => cat.id === user?.category_id);

      
      return {
        id,
        event_id,
        name: user?.name,
        brand_name: user?.brand_name,
        amount: "R$" + amount,
        instagram_url: (
          <Link
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={`https://www.instagram.com/${
              user?.instagram_url.split("@")[1]
            }/`}
          >
            {user?.instagram_url}
          </Link>
        ),
        category: (category && category.name) || "",
        is_paid: is_paid ? "Sim" : "Não",
        start_date: parseDate(start_date),
        date: parseDate(date),
        actions: (
          <>
            <UpdateButton
              id={id}
              updateCommisions={updateSubscriptions}
              sub={arr[i]}
            />
          </>
        ),
      };
    }
  );
};

export default function ComissionsTable() {
  const [commissions, setCommissions] = React.useState([]);
  const users = useAdminStore((state) => state.users);
  const categories = useCategory((state) => state.categories);
  const events = useAdminStore((state) => state.events);
  const subs = useAdminStore((state) => state.subscriptions);

  const editedEvents = events.map((event) => {
    return ({
      ...event,
      commissions: commissions.filter(com => (subs.find(s => s.id === com.subscription_id))?.event_id === event.id)
    })
  })

  React.useEffect(() => {
    syncCommissions();    
  }, []);

  function syncCommissions() {
    getAll().then((x) => {
      setCommissions(x);
    });
  }

  React.useEffect(() => {
       
  }, []);

  function exportData() {
    const excelsubs = commissions.map((obj) => ({ ...obj, event_name: (events.find(ev => ev.id === (subs.find(sub => sub.event_id === ev.id)).event_id))?.name || "EVENTO APAGADO", user_name: (users.find(ev => ev.id === obj.user_id))?.name }))
    const worksheet = XLSX.utils.json_to_sheet(excelsubs)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Comissoes");
    XLSX.utils.sheet_add_aoa(worksheet, [["ID", "Usuario ID", "Inscricao ID", "Valor", "Codigo PIX", "Data", "Pago", "Criado Em", "Atualizado Em", "Nome do Evento", "Nome Usuario"]], { origin: "A1" });
    XLSX.writeFile(workbook, "Comissoes.xlsx", { compression: true });
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <SectionTitle title="Comissões" />
        <Button variant="contained" onClick={exportData}>EXPORTAR DADOS COMO EXCEL</Button>
      </div>
      {users && categories && events && commissions && (
        <Paper sx={{ p: 2 }}>
        {editedEvents.map((event) => (
          <Accordion key={event.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{event.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            
          <Grid container my={0}>
              <Grid item xs={12}>
                <h2>Valor total de comissões do evento: <span style={{color: "green"}}>R$ {event.commissions.reduce((x, y) => x += +y.amount, 0)}</span></h2>
                <div style={{ height: 660, width: "100%" }}>
                  {users && <DataGrid
                    rows={createRows(
                      event.commissions,
                      syncCommissions,
                      users,
                      categories
                    )}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                  />}
                </div>
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>
        ))}  
        </Paper>
      )}
    </>
  );
}

/* 

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


<Grid container my={3}>
              <Grid item xs={12}>
                <SectionTitle title="Tabela de Comissões" />
                <div style={{ height: 660, width: "100%" }}>
                  {users && <DataGrid
                    rows={createRows(
                      commissions,
                      syncCommissions,
                      users,
                      categories
                    )}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                  />}
                </div>
              </Grid>
            </Grid>

*/
