import React from "react";
import { getUserOnly } from "../../../services/expositor/subscriptions.service";
import { Grid, Paper, Typography, Button } from "@mui/material";
import SectionTitle from "../../../ui-components/SectionTitle";
import { parseDateLocale } from "../../../utils/parseDate";
import { QRCodeSVG } from "qrcode.react";
import { ModalComponent, useModal } from "../../../ui-components/Modal";
import UpdateSubscriptionForm from "./UpdateSubscriptionForm";
import {
  deleteSubscription,
} from "../../../services/subscriptions.service";
import { getAllEvents } from "../../../services/event.service";

const space = {
  "shared": "Compartilhado",
  "exclusive": "Exclusivo",
  "foodcourt": "Praça de Alimentação",
  "trailer": "Trailer",
  "bike": "Bike"
}
export default function PreSubscriptions() {
  const [userSubscriptions, setUserSubscriptions] = React.useState([]);
  const [selectedSub, setSelectedSub] = React.useState({});
  const [events, setEvents] = React.useState([]);
  const { open, handleOpen, handleClose } = useModal();

  function syncEvents() {
    getAllEvents().then((eventsArr) => {
      setEvents(eventsArr);
    });
  }

  React.useEffect(() => {
    syncEvents();
  }, []);

  async function syncSubscriptions() {
    setUserSubscriptions(await getUserOnly());
  }

  React.useEffect(() => {
    syncSubscriptions();
  }, []);

  React.useEffect(() => {
    console.log(userSubscriptions);
  }, [userSubscriptions]);

  return (
    <>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        sx={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <SectionTitle title="Editando pré-inscrição para evento:" />
        <UpdateSubscriptionForm
          handleClose={handleClose}
          selectedSub={selectedSub}
          events={events}
        />
      </ModalComponent>
      <Grid container>
        <Paper sx={{ p: 2, width: "100%" }}>
          <SectionTitle title={"Pre Inscrições em avaliação 🔄:"} />
          {userSubscriptions &&
            userSubscriptions
              .filter((x) => x.status_id === 0)
              .map((sub) => (
                <Grid pb="10px" key={sub.id}>
                  <Grid>
                    <Paper
                      sx={{
                        borderLeft: sub.is_paid
                          ? "6px solid green"
                          : "6px solid gray",
                        p: "8px 10px",
                      }}
                    >
                      <Grid
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Grid>
                          <Typography variant="h5">
                            Evento: Feira do Bem
                          </Typography>

                          <Typography my={2}>Suas Escolhas:</Typography>

                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Data de início: {" "}
                            </span>
                            {parseDateLocale(sub.start_date)}
                          </Typography>
                          <Typography>
                          <span style={{ color: "#a8518e" }}>
                              Data de fim: {" "}
                            </span> 
                            {parseDateLocale(sub.end_date)}
                          </Typography>
                          <Typography>
                          <span style={{ color: "#a8518e" }}>
                              Tipo de espaço: {" "}
                            </span>
                            {space[sub.space_type]}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Mesa extra:{" "}
                            </span>
                            {sub.extra_table ? "Sim" : "Não"}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Quantas mesas:{" "}
                            </span>
                            {(sub.extra_table && sub.table_quantity) || 0}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Cadeira Extra:{" "}
                            </span>
                            {sub.extra_chair ? "Sim" : "Não"}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Quantas cadeiras:{" "}
                            </span>
                            {(sub.extra_table && sub.table_quantity) || 0}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>Itens: </span>
                            {sub.items}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>
                              Observação:{" "}
                            </span>
                            {sub.notes}
                          </Typography>
                          <Typography>
                            <span style={{ color: "#a8518e" }}>Valor: </span> R${" "}
                            {sub.amount},00
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "8px",
                              background: "gray",
                              borderRadius: "10px",
                              textAlign: "center",
                            }}
                          >
                            Última atualização:{" "}
                            {parseDateLocale(sub.updated_at)}
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              setSelectedSub(sub);
                              handleOpen();
                            }}
                          >
                            Alterar Informações
                          </Button>
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Você realmente deseja cancelar esta inscrição? Essa operação é irreversível!"
                                )
                              ) {
                                deleteSubscription(sub.id).then(() => {
                                  alert("Inscrição apagada com sucesso!");
                                  window.location.reload();
                                });
                              } else {
                                console.log("cancelado");
                              }
                            }}
                            variant="outlined"
                          >
                            Apagar Inscrição
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <div id={"sub" + sub.id} style={{ display: "none" }}>
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 4,
                      }}
                    >
                      <img
                        style={{ marginBottom: "12px" }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg/2560px-Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg.png"
                        alt=""
                        width={300}
                        height={100}
                      />
                      <QRCodeSVG value={sub.pix_code} size={300} />
                    </Paper>
                  </div>
                </Grid>
              ))}
        </Paper>
      </Grid>
    </>
  );
}
