import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid, Paper } from "@mui/material";
import {
  createEvent,
  getAllEvents,
} from "../../../services/event-type.service";
import { parseDate } from "../../../utils/parseDate";
import SectionTitle from "../../../ui-components/SectionTitle";
import CurrencyInput from "react-currency-input-field";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import UpdateButton from "./OperationButtons/UpdateButton";
import DeleteButton from "./OperationButtons/DeleteButton";
import ViewButton from "./OperationButtons/ViewButton";

import { TextField } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Nome", width: 260 },
  {
    field: "actions",
    headerName: "Ações",
    width: 160,
    renderCell: (param) => <>{param.value}</>,
  },
];
interface Event {
  active: boolean;
  cover: string;
  created_at: Date;
  description: string;
  ends_at: Date;
  name: string;
  updated_at: Date;
  starts_at: Date;
  id: number;
  limitdate: Date;
  event_data: any;
  event_address: string;
}

const createRows = (events: Event[], updateEvents: Function) => {
  return events.map((eventInfo, i, arr) => {
    const { id } = eventInfo;
    const { name } = eventInfo.event_data;
    return {
      id: eventInfo.id,
      name,
      actions: (
        <>
          <DeleteButton id={id} updateEvents={updateEvents} />

          <ViewButton id={id} updateEvents={updateEvents} event={arr[i]} />
        </>
      ),
    };
  });
};

export default function EventTable() {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function syncEvents() {
    getAllEvents().then((eventsArr) => {
      setEvents(eventsArr);
    });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let data: any = Object.fromEntries(new FormData(e.target).entries());
    const { name } = data;
    const eventData = {
      name,
      shared: {
        table_price_shared_1: +data.table_price_shared_1.replace(/R\$/, ""),
        table_price_shared_2: +data.table_price_shared_2.replace(/R\$/, ""),

        chair_price_shared_1: +data.chair_price_shared_1.replace(/R\$/, ""),
        chair_price_shared_2: +data.chair_price_shared_2.replace(/R\$/, ""),

        subscription_price_1_shared: +data.subscription_price_1_shared.replace(
          /R\$/,
          ""
        ),
        subscription_price_2_shared: +data.subscription_price_2_shared.replace(
          /R\$/,
          ""
        ),
        percentage_shared: +data.percentage_shared,
      },
      exclusive: {
        table_price_exclusive_1: +data.table_price_exclusive_1.replace(
          /R\$/,
          ""
        ),
        table_price_exclusive_2: +data.table_price_exclusive_2.replace(
          /R\$/,
          ""
        ),

        chair_price_exclusive_1: +data.chair_price_exclusive_1.replace(
          /R\$/,
          ""
        ),
        chair_price_exclusive_2: +data.chair_price_exclusive_2.replace(
          /R\$/,
          ""
        ),

        subscription_price_1_exclusive:
          +data.subscription_price_1_exclusive.replace(/R\$/, ""),
        subscription_price_2_exclusive:
          +data.subscription_price_2_exclusive.replace(/R\$/, ""),
        percentage_exclusive: +data.percentage_exclusive,
      },
      foodcourt: {
        subscription_price_1_foodcourt:
          +data.subscription_price_1_foodcourt.replace(/R\$/, ""),
        subscription_price_2_foodcourt:
          +data.subscription_price_2_foodcourt.replace(/R\$/, ""),

        table_price_foodcourt_1: +data.table_price_foodcourt_1.replace(
          /R\$/,
          ""
        ),
        table_price_foodcourt_2: +data.table_price_foodcourt_2.replace(
          /R\$/,
          ""
        ),

        chair_price_foodcourt_1: +data.chair_price_foodcourt_1.replace(
          /R\$/,
          ""
        ),
        chair_price_foodcourt_2: +data.chair_price_foodcourt_2.replace(
          /R\$/,
          ""
        ),
        percentage_foodcourt: +data.percentage_foodcourt,
      },
      trailer: {
        subscription_price_1_trailer:
          +data.subscription_price_1_trailer.replace(/R\$/, ""),
        subscription_price_2_trailer:
          +data.subscription_price_2_trailer.replace(/R\$/, ""),

        chair_price_trailer_1: +data.chair_price_trailer_1.replace(/R\$/, ""),
        chair_price_trailer_2: +data.chair_price_trailer_2.replace(/R\$/, ""),
        percentage_trailer: +data.percentage_trailer,
      },
      bike: {
        subscription_price_1_bike: +data.subscription_price_1_bike.replace(
          /R\$/,
          ""
        ),
        subscription_price_2_bike: +data.subscription_price_2_bike.replace(
          /R\$/,
          ""
        ),

        chair_price_bike_1: +data.chair_price_bike_1.replace(/R\$/, ""),
        chair_price_bike_2: +data.chair_price_bike_2.replace(/R\$/, ""),
        percentage_bike: +data.percentage_bike,
      },
    };
    const reqObj = {
      event_data: JSON.stringify(eventData),
    };

    if (Date.parse(data.starts_at) > Date.parse(data.ends_at)) {
      alert("Data de início não pode ser maior que a data de término.");
    } else {
      handleClose();
      createEvent(reqObj).then(() => {
        syncEvents();
      });
    }
  };

  useEffect(() => {
    syncEvents();

    return () => {
      syncEvents();
    };
  }, []);

  useEffect(() => {
    syncEvents();
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Criar novo Evento
          </Typography>
          <form onSubmit={handleSubmit} style={{ gap: "3px" }}>
            <>
              <TextField
                required
                id="name"
                name="name"
                label="Nome do Tipo de Evento"
                fullWidth
                autoComplete="given-name"
                variant="standard"
              />

              <Typography
                id="modal-modal-title"
                sx={{ m: 4, textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                Tabela de Valores Tenda - Exclusivo
              </Typography>

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 1 Dia - Exclusivo:{" "}
              </Typography>
              <CurrencyInput
                id="subscription_price_1_exclusive"
                name="subscription_price_1_exclusive"
                prefix="R$"
                placeholder="Custo de Inscrição 1 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 2 Dias - Exclusivo:{" "}
              </Typography>
              <CurrencyInput
                id="subscription_price_2_exclusive"
                name="subscription_price_2_exclusive"
                prefix="R$"
                placeholder="Custo de Inscrição 2 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Mesa 1 Dia - Exclusivo:{" "}
              </Typography>
              <CurrencyInput
                id="table_price_exclusive_1"
                name="table_price_exclusive_1"
                prefix="R$"
                placeholder="Custo da Mesa"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Mesa 2 Dias - Exclusivo:{" "}
              </Typography>
              <CurrencyInput
                id="table_price_exclusive_2"
                name="table_price_exclusive_2"
                prefix="R$"
                placeholder="Custo da Mesa"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 1 Dia - Exclusivo:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_exclusive_1"
                name="chair_price_exclusive_1"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 2 Dias - Exclusivo:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_exclusive_2"
                name="chair_price_exclusive_2"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <TextField
                required
                id="percentage_exclusive"
                name="percentage_exclusive"
                label="Porcentagem de Vendas - Exclusivo"
                fullWidth
                autoComplete="description"
                variant="standard"
                type="number"
                sx={{ my: 2 }}
              />

              <Typography
                id="modal-modal-title"
                sx={{ m: 4, textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                Tabela de Valores Tenda - Compartilhado
              </Typography>

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 1 Dia - Compartilhado:{" "}
              </Typography>
              <CurrencyInput
                id="subscription_price_1_shared"
                name="subscription_price_1_shared"
                prefix="R$"
                placeholder="Custo de Inscrição 1 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 2 Dias - Compartilhado:{" "}
              </Typography>
              <CurrencyInput
                id="subscription_price_2_shared"
                name="subscription_price_2_shared"
                prefix="R$"
                placeholder="Custo de Inscrição 2 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Mesa 1 Dia - Compartilhado:{" "}
              </Typography>
              <CurrencyInput
                id="table_price_shared_1"
                name="table_price_shared_1"
                prefix="R$"
                placeholder="Custo da Mesa"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Mesa 2 Dias - Compartilhado:{" "}
              </Typography>
              <CurrencyInput
                id="table_price_shared_2"
                name="table_price_shared_2"
                prefix="R$"
                placeholder="Custo da Mesa"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 1 Dia - Compartilhado:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_shared_1"
                name="chair_price_shared_1"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 2 Dias - Compartilhado:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_shared_2"
                name="chair_price_shared_2"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <TextField
                required
                id="percentage_shared"
                name="percentage_shared"
                label="Porcentagem de Vendas - Compartilhado"
                fullWidth
                autoComplete="description"
                variant="standard"
                type="number"
                sx={{ my: 2 }}
              />

              <Typography
                id="modal-modal-title"
                sx={{ m: 4, textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                Tabela de Valores Praça de Alimentação - 1 dia
              </Typography>

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 1 Dia:{" "}
              </Typography>
              <CurrencyInput
                id="subscription_price_1_foodcourt"
                name="subscription_price_1_foodcourt"
                prefix="R$"
                placeholder="Custo de Inscrição 1 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Mesa 1 Dia :{" "}
              </Typography>
              <CurrencyInput
                id="table_price_foodcourt_1"
                name="table_price_foodcourt_1"
                prefix="R$"
                placeholder="Custo da Mesa"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 1 Dia :{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_foodcourt_1"
                name="chair_price_foodcourt_1"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <Typography
                id="modal-modal-title"
                sx={{ m: 4, textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                Tabela de Valores Praça de Alimentação - 2 dias
              </Typography>

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 2 Dias:{" "}
              </Typography>
              <CurrencyInput
                id="subscription_price_2_foodcourt"
                name="subscription_price_2_foodcourt"
                prefix="R$"
                placeholder="Custo de Inscrição 1 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Mesa 2 Dias :{" "}
              </Typography>
              <CurrencyInput
                id="table_price_foodcourt_2"
                name="table_price_foodcourt_2"
                prefix="R$"
                placeholder="Custo da Mesa"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 2 Dias :{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_foodcourt_2"
                name="chair_price_foodcourt_2"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <TextField
                required
                id="percentage_foodcourt"
                name="percentage_foodcourt"
                label="Porcentagem de Vendas - Praça de Alimentação"
                fullWidth
                autoComplete="description"
                variant="standard"
                type="number"
                sx={{ my: 2 }}
              />

              <Typography
                id="modal-modal-title"
                sx={{ m: 4, textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                Tabela de Valores Trailer
              </Typography>

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 1 Dia:{" "}
              </Typography>

              <CurrencyInput
                id="subscription_price_1_trailer"
                name="subscription_price_1_trailer"
                prefix="R$"
                placeholder="Custo de Inscrição 1 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 2 Dias:{" "}
              </Typography>

              <CurrencyInput
                id="subscription_price_2_trailer"
                name="subscription_price_2_trailer"
                prefix="R$"
                placeholder="Custo de Inscrição 2 Dias"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 1 Dias:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_trailer_1"
                name="chair_price_trailer_1"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 2 Dias:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_trailer_2"
                name="chair_price_trailer_2"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <TextField
                required
                id="percentage_trailer"
                name="percentage_trailer"
                label="Porcentagem de Vendas - Trailer"
                fullWidth
                autoComplete="description"
                variant="standard"
                type="number"
                sx={{ my: 2 }}
              />

              <Typography
                id="modal-modal-title"
                sx={{ m: 4, textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                Tabela de Valores Bike
              </Typography>

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 1 Dia:{" "}
              </Typography>

              <CurrencyInput
                id="subscription_price_1_bike"
                name="subscription_price_1_bike"
                prefix="R$"
                placeholder="Custo de Inscrição 1 Dia"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo de Inscrição 2 Dias:{" "}
              </Typography>

              <CurrencyInput
                id="subscription_price_2_bike"
                name="subscription_price_2_bike"
                prefix="R$"
                placeholder="Custo de Inscrição 2 Dias"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 1 Dias:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_bike_1"
                name="chair_price_bike_1"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
                Custo da Cadeira 2 Dias:{" "}
              </Typography>
              <CurrencyInput
                id="chair_price_bike_2"
                name="chair_price_bike_2"
                prefix="R$"
                placeholder="Custo da Cadeira"
                defaultValue={0}
                decimalsLimit={2}
                style={{
                  background: "transparent",
                  outline: "none",
                  borderBottom: "1px solid white",
                  color: "green",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "100%",
                  padding: "8px",
                  marginBottom: "8px",
                }}
              />

              <TextField
                required
                id="percentage_bike"
                name="percentage_bike"
                label="Porcentagem de Vendas - Trailer"
                fullWidth
                autoComplete="description"
                variant="standard"
                type="number"
                sx={{ my: 2 }}
              />

              <Button type={"submit"} sx={{ m: 2 }}>
                Criar Novo Tipo Evento
              </Button>
            </>
          </form>
        </Box>
      </Modal>
      <Grid container spacing={3} my={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
              <SectionTitle title="Tipos de Eventos" />
              <div>
                <Button sx={{ m: 1 }} onClick={handleOpen} variant="contained">
                  Criar novo tipo de Evento +
                </Button>
              </div>
            </Grid>
            <div style={{ height: 660, width: "100%" }}>
              {events && (
                <DataGrid
                  rows={createRows(events, syncEvents)}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection={false}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
