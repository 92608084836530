import React from "react";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { parseDateLocale } from "../../../../../utils/parseDate";
import DateCalculator from "../../../../priceTable";
import { useAdminStore } from "../../../../../context/admin-store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "80vh"
};

interface Event {
  active: boolean;
  cover: string;
  created_at: Date;
  description: string;
  ends_at: Date;
  name: string;
  updated_at: Date;
  starts_at: Date;
  id: number;
  limitdate: Date;
}

export default function ViewButton({
  id,
  updateEvents,
  event,
}: {
  id: number;
  updateEvents: Function;
  event: Event;
}) {
  const [open, setOpen] = React.useState(false);
  const eventTypes = useAdminStore((state) => state.eventTypes);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {starts_at, ends_at,limitdate} = event;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Vendo todas as informações do Evento :
          </Typography>
          <>
            <p>ID: {event.id}</p>
            <p>Nome: {event.name}</p>
            <p>Descrição: {event.description}</p>
            <p>Evento ativo: {event.active ? "Sim" : "Não"}</p>
            <p>Data de início: {parseDateLocale(starts_at)}</p>
            <p>Data de Término: {parseDateLocale(ends_at)}</p>
            <p>Data de limite para pagamento: {parseDateLocale(limitdate)}</p>


            <DateCalculator startDate={starts_at} endDate={ends_at} eventData1={event} eventTypes={eventTypes}></DateCalculator>
          </>
        </Box>
      </Modal>
      <IconButton title="Pressione 2 vezes para ver mais" onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
    </>
  );
}
