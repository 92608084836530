import { Paper, Grid } from '@mui/material'
import React from 'react'
import SectionTitle from '../../ui-components/SectionTitle'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const images = [1,2,3,5,6,7,8,9,10]

export default function Welcome() {
  return (
    <Paper sx={{ p: '16px' }}>

      <SectionTitle title='Seja bem-vindo a plataforma de expositores da Feira do Bem!' />

      <AwesomeSlider>
        {images.map(image => (
          <div key={image}><img style={{opacity: "0.6"}} src={`https://feiradobemoficial.com.br/wp-content/themes/storefront/assets/images-carroussel/${image}.jpeg`} alt=""/></div>
        ))}
      </AwesomeSlider>


      <Grid sx={{mt: "100px"}}>

        <SectionTitle title='Gentileza gera gentileza...' ></SectionTitle>

        <p>
          <p>É com imensa satisfação e gratidão que te recebemos na nossa plataforma que reúne potenciais expositores para a FEIRA DO BEM.</p>

          <p>Buscamos gerar oportunidade de trabalho para aqueles que acreditam no propósito do evento: “Espalhar amor e gentileza, e conectar pessoas com iniciativas do BEM”.</p>

          <p>Uma vez aprovados, iremos te acolher para crescermos juntos. A cada participação no evento, buscaremos dar um passo adiante para encantarmos cada vez mais os nossos visitantes.</p>

          <p>Organizamos a FEIRA DO BEM pensando em oferecer uma experiência diferente para quem nos prestigia. Um evento que reúne cultura, esporte e lazer! Atrações para toda a família, todas as idades e gêneros.</p>

          <ul>
            <li>Cerca de 150 marcas autorais</li>
            <li>Festival gastronômico</li>
            <li>Shows de música ao vivo</li>
            <li>Oficinas lúdicas de hora em hora ( 10 vagas gratuitas/oficina )</li>
            <li>Brincadeiras de antigamente</li>
          </ul>

          <p>E mais, a cada edição da FEIRA DO BEM, recebemos cerca de 05 ONG’s/Projetos Sociais* totalmente subsidias pelo evento. Assim sendo, sua participação contribui para termos um mundo melhor e mais inclusivo. *Nós não doamos dinheiro, nós geramos trabalho para eles.</p>
        </p>
      </Grid>

    </Paper>
  )
}
