import { getToken } from "./auth.service"

export const getDocuments = async () => {
  return await (await fetch(`${process.env.REACT_APP_API_URL}/documents`)).json()
}

export const deleteDocuments = async (id) => {
  return await (await fetch(`${process.env.REACT_APP_API_URL}/documents/${id}`, {
    method: "DELETE",
    headers: {
      "Authorization" : `Bearer ${getToken()}`
    }
  })).json()
}

