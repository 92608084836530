import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { getToken } from "../services/auth.service";
import { isAdminRequest } from "../services/auth.service";

interface Login {
    token: string | null;
    isAdmin: boolean | null;
    loginToken?(token: string): void;
    getAdminInfo?(): void;
}

const LoginContext = createContext<Login | null>({
    token: null,
    isAdmin: null,
});

export const LoginProvider = ({children} : {children: any}) => {
    const [token, setToken] = useState(getToken() || null);
    const [isAdmin, setIsAdmin] = useState<Login["isAdmin"]>(null);

    const loginToken = (token : string) => {
        setToken(token)
    }

    const getAdminInfo = async () => {
        const adminInfo = await isAdminRequest();
        setIsAdmin(adminInfo)
    }

    useEffect(() => {
        getAdminInfo();
    }, [token])

    return <LoginContext.Provider value={{
        token,
        isAdmin,
        loginToken,
        getAdminInfo
    }}>{children}</LoginContext.Provider>
}

export const useLoginContext = () => useContext(LoginContext)