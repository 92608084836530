import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { getDocuments } from "../../../services/documents.service";
import { getAllEvents } from "../../../services/event.service";
import { getUserOnly } from "../../../services/expositor/subscriptions.service";
import { parseDate } from "../../../utils/parseDate";
import { getToken, getUserInfo } from "../../../services/auth.service";
import { Delete } from "@mui/icons-material";

export const getReceipts = async () => {
  return await (
    await fetch(`${process.env.REACT_APP_API_URL}/receipts`)
  ).json();
};

export const deleteReceipt = async (id) => {
  return await (
    await fetch(`${process.env.REACT_APP_API_URL}/receipts/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  ).json();
};

export default function Documents() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [documents, setDocuments] = React.useState([]);
  const [receipts, setReceipts] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [events, setEvents] = React.useState([]);

  React.useEffect(() => {
    getUserOnly().then((data) => {
      setSubscriptions(data);
    });
  }, []);

  React.useEffect(() => {
    getDocuments().then((data) => {
      setDocuments(data);
    });
  }, []);

  React.useEffect(() => {
    getReceipts().then((data) => {
      setReceipts(data);
    });
  }, []);

  React.useEffect(() => {
    getAllEvents().then((data) => {
      setEvents(data);
      console.log(data, documents, subscriptions);
    });
  }, []);

  const eventsFromUser = new Set([...subscriptions.map((s) => s.event_id)]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {events &&
        events
          .filter((event) => eventsFromUser.has(event.id))
          .map((event) => (
            <Accordion
              key={event.id}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {event.name}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>{`${parseDate(
                  event.starts_at
                )} ate ${parseDate(event.ends_at)}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Documentos
                </Typography>
                <ImageList cols={5}>
                  {documents &&
                    documents
                      .filter((doc) => doc.event_id === event.id)
                      .map((item) => (
                        <a
                          key={item.id}
                          target={"_blank"}
                          rel="noreferrer"
                          href={item.url}
                        >
                          <ImageListItem>
                            <img
                              src={
                                item.file_type.includes("image/")
                                  ? item.url
                                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAbFBMVEX///8AAABLS0vc3NwwMDDo6Oi0tLTCwsJ0dHSGhoY9PT0/Pz/09PQcHBx4eHg4ODhVVVUUFBTIyMiXl5eqqqqhoaFtbW3S0tLOzs4uLi7i4uJlZWUlJSVfX19QUFCsrKwYGBiAgIBFRUW6urrFevtnAAADDElEQVR4nO3d61IaQRRF4eGiEMMtQAAxaozv/46JSf50M1xK9j5ntNb3ANOs6p7pES27aQAAAAAAAAAAAD685WrWnw9F7vvZObXlQ0/sLjupsP6m7utW4n5o6OtS4ndP3x9fs9P+2dgCO5K4MAZ2ItE5g2/S70XfPdiRxIE9MHuhmraJ7iTeRgSmJv6IKcxLHAUF5iU+1h/k6XV7c51jz+akxOpTTLfXX/LonZ2SuKs+xERwzePProx9sVpRghk8+XROSBwXH+BJcs1T+0/8Qn0qxn+WXPPkDhs+i339Ij3zDhE9i2XhQHLNM29JwbOYUBg8ixmFsbPoLxzPc2fRX/ilafmWMnAWIwonLT++xCVGFDaTzFkMKWwm07zEmMLWxKDHTVBh4ixGFebNYlhhM/mZM4txhVkLNbCwddOYS0Y8JbKwdRbtiaGFKVt/bGHGvRhcmDCL0YXxm0Z4YXhifGHT3Icm+gvvZuPS7OBXJdbElO9p2tj2xc4U9oaSsQ91p9C1UDtU2HuUjF7rUqHnbzUpfA8KKdSi8D0opFDLUXgzutT6gxZebv/pCwcUClDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaHC5y8sT3jcWcY4rjwFVXOCZq08N35tGeO48j9DLixjvASsk+N+FaO/WMaozpKNXabVUb2eFbQtB5GcB3ypSTW25hTUA9UoijOdL7SrD2QxjfNQDdNbXH0u9yW2t/164I2pcFkPlMb2DGg5EyWF63yLpnnNTvtvZCtsWs56TeA8KXBwfvgAe2NhveunML8vbrL7ejNvYP3+Hc9zwkwhdxbH/sDcezHoZ7ZB1s4/tz5FC+uWowntps9hfW9GB6/hZptlaN9fy9WsPx/6zfuzVfS3QgAAAAAAAAAAAAa/ARXQN87ZDmHrAAAAAElFTkSuQmCC"
                              }
                              alt={item.title}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item["file_type"]}
                            />
                          </ImageListItem>
                        </a>
                      ))}
                </ImageList>
              </AccordionDetails>
            </Accordion>
          ))}
    </div>
  );
}
