import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SectionTitle from "../../ui-components/SectionTitle";
import { ModalComponent, useModal } from "../../ui-components/Modal";
import { TextField } from "@mui/material";
import { getAllEvents } from "../../services/event.service";
import { getAllEvents as getAllEvents1 } from "../../services/event-type.service";
import { getUserOnly } from "../../services/expositor/subscriptions.service";
import {
  createCommmission,
  deleteCommission,
  getAllCommissions,
  updateCommission
} from "../../services/commissions.service";
import { getUserInfo } from "../../services/auth.service";
import { parseDateLocale } from "../../utils/parseDate";
import { QRCodeSVG } from "qrcode.react";
import CurrencyInput from "react-currency-input-field";

export default function Comissions() {
  const [events, setEvents] = useState([]);
  const [subs, setSubs] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [com, setCurrentCom] = useState<any>({});
  const [currentEvent, setCurrentEvent] = useState<any>({})
  const [currentSub, setCurrentSub] = useState<any>({})
  const [eventTypes, setEventTypes] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setEventTypes(await getAllEvents1());
    })();
  }, []);

  const { open, handleClose, handleOpen } = useModal();
  const {
    open: open2,
    handleClose: handleClose2,
    handleOpen: handleOpen2,
  } = useModal();

  useEffect(() => {
    getAllEvents().then((x) => {
      setEvents(x);
    });
  }, []);

  useEffect(() => {
    getUserOnly().then((x) => {
      setSubs(x);
    });
  }, []);

  useEffect(() => {
    getAllCommissions().then((x: any) => {
      setCommissions(x);
    });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const userData: any = Object.fromEntries(formData.entries());
    const percentage = currentEvent.event_data ? +eventTypes.find((e) => +e.id === +currentEvent.event_data.eventType_id).event_data[currentSub.space_type]["percentage_" + currentSub.space_type] : 10
    userData.user_id = +getUserInfo().id;
    userData.subscription_id = +userData.subscription_id;
    userData.amount = (+userData.amount.replace(/R\$/, "").replace(".","").replace(",", ".") * percentage) / 100;    
    userData.date = (subs.find((sub) => userData.subscription_id === sub.id)).start_date

    createCommmission(userData).then(() => {
      handleClose();
      window.location.reload()
    });
  };

  const handleSubmitEdit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const userData: any = Object.fromEntries(formData.entries());
    const percentage = currentEvent.event_data ? +eventTypes.find((e) => +e.id === +currentEvent.event_data.eventType_id).event_data[currentSub.space_type]["percentage_" + currentSub.space_type] : 10
    userData.user_id = +getUserInfo().id;
    userData.amount = (+userData.amount.replace(/R\$/, "").replace(".","").replace(",", ".") * percentage) / 100;        
    
    updateCommission(userData, com.id).then((x) => {
      alert('Comissão editada com sucesso!')
      handleClose2();
      window.location.reload()
    });
  };

  useEffect(() => {
    console.log(currentEvent)
  }, [currentEvent])

  const percentage = currentEvent?.event_data ?  eventTypes.find((e) => +e.id === +currentEvent.event_data.eventType_id).event_data[currentSub.space_type]["percentage_" + currentSub.space_type] : "10"

  useEffect(() => {
    console.log(percentage);
    console.log(currentSub);
    
  }, [currentEvent])

  return (
    <>
      <ModalComponent open={open} handleClose={handleClose}>
        <form style={{ gap: "10px" }} onSubmit={handleSubmit}>
          <SectionTitle title="Declarar uma comissão" />
          <Grid>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                gap: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                Selecione o Evento
              </InputLabel>
              <Select
                required
                labelId="subscription_id"
                id="subscription_id"
                name="subscription_id"
                onChange={(e) => {
                  const selectedSub = subs.find(x => x.id === e.target.value) 
                  const event = events.find((event) => selectedSub.event_id === event.id)  
                  setCurrentSub(selectedSub)
                  setCurrentEvent(event)
                }}
                fullWidth
              >
                {subs &&
                  subs
                    .filter((x) => x.status_id === 1)
                    .map((sub) => {
                      const event = events.find((event) => sub.event_id === event.id)                      
                      return (
                        <MenuItem key={sub.id} value={sub.id}>
                          {event.name}
                        </MenuItem>
                      )
                    })}
              </Select>
            </FormControl>

            <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
            Valor total da sua renda bruta:{" "}
            </Typography>

            <CurrencyInput
              id="amount"
              name="amount"
              prefix="R$"
              placeholder="Valor total da sua renda bruta"
              defaultValue={0}
              decimalsLimit={2}
              style={{
                background: "transparent",
                outline: "none",
                borderBottom: "1px solid white",
                color: "green",
                fontSize: "1rem",
                fontWeight: 600,
                width: "100%",
                padding: "8px",
              }}
            />
            <Typography
              sx={{ marginTop: "20px", fontWeight: 500, marginBottom: "20px" }}
            >
              {currentEvent.event_data ? "Observação: Nossa comissão é de " + percentage + "%" : "Observação: Nossa comissão é de 10%"} 
            </Typography>

            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ mr: "10px" }}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Criar Comissão
            </Button>
          </Grid>
        </form>
      </ModalComponent>

      <ModalComponent open={open2} handleClose={handleClose2}>
        <form style={{ gap: "10px" }} onSubmit={handleSubmitEdit}>
          <SectionTitle title="Editar uma comissão" />
          <Grid>
            <Typography sx={{ marginTop: "20px", fontWeight: 500 }}>
            Valor total da sua renda bruta:{" "}
            </Typography>

            <CurrencyInput
              id="amount"
              name="amount"
              prefix="R$"
              placeholder="Valor total da sua renda bruta"
              defaultValue={0}
              decimalsLimit={2}
              style={{
                background: "transparent",
                outline: "none",
                borderBottom: "1px solid white",
                color: "green",
                fontSize: "1rem",
                fontWeight: 600,
                width: "100%",
                padding: "8px",
              }}
            />
            <Typography
              sx={{ marginTop: "20px", fontWeight: 500, marginBottom: "20px" }}
            >
              Observação: Nossa comissão é de 10%
            </Typography>

            <TextField
              required
              id="firstName"
              name="date"
              label="Dia do evento que voce deseja pagar"
              fullWidth
              type={"date"}
              defaultValue="2022-01-01"
              sx={{ my: 2 }}
              variant={"standard"}
            />
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ mr: "10px" }}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Editar Comissão
            </Button>
          </Grid>
        </form>
      </ModalComponent>

      <Grid display="flex" justifyContent="space-between" mb="20px">
        <SectionTitle title="Suas Comissões" />
        <Button onClick={handleOpen} variant="outlined">
          Declarar Comissão +
        </Button>
      </Grid>
      {commissions &&
        commissions.map((commission) => (
          <Grid pb="10px" key={commission.id}>
            <Grid>
              <Paper
                sx={{
                  height: "150px",
                  borderLeft: commission.is_paid
                    ? "6px solid green"
                    : "6px solid red",
                  p: "8px 10px",
                }}
              >
                <Grid
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Grid height={"130px"}>
                    <Typography variant="h5">Evento: Feira do Bem</Typography>
                    <Typography>
                      Data: {parseDateLocale(commission.date)}
                    </Typography>
                    <Typography>
                      Pagamento confirmado pelo evento:{" "}
                      {commission.is_paid ? "Sim" : "Nao"}
                    </Typography>
                    <Typography variant="h5" color={"green"}>
                      Valor: R${commission.amount}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        const s = document.getElementById(
                          "commission" + commission.id
                        ).style.display;
                        document.getElementById(
                          "commission" + commission.id
                        ).style.display = s === "none" ? "block" : "none";
                      }}
                      variant="contained"
                    >
                      Pagar Pix
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setCurrentCom(commission);
                        handleOpen2();
                      }}
                    >
                      Alterar dados
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Você realmente deseja apagar essa comissão? Essa ação pode ser irreversível!"
                          )
                        ) {
                          deleteCommission(commission.id).then(() => {
                            alert("Comissão apagada com sucesso!");
                            window.location.reload();
                          });
                        } else {
                          console.log("cancelado");
                        }
                      }}
                    >
                      Apagar Comissão
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <div id={"commission" + commission.id} style={{ display: "none" }}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 4,
                }}
              >
                <img
                  style={{ marginBottom: "12px" }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg/2560px-Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg.png"
                  alt=""
                  width={300}
                  height={100}
                />
                <QRCodeSVG value={commission.pix_code} size={300} />
              </Paper>
            </div>
          </Grid>
        ))}
    </>
  );
}
