import { getToken } from "../auth.service"

export const createSubscription = (data: any) => {
  try {
    fetch(`${process.env.REACT_APP_API_URL}/subscriptions`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${getToken()}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    return "Sub criada com sucesso!"
  } catch (erro) {
    console.error(erro)
    return "Algum problema ocorreu na criacao de sua inscricao!"
  }
}

export const getUserOnly = async () => {
  try {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/user`, {
      headers: {
        "Authorization": `Bearer ${getToken()}`,
      }
    })
    return await request.json();
  } catch (error) {
    return []
  }
}