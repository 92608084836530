import React from "react";
import { IconButton, Button, Grid, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteEvent } from "../../../../services/event-type.service";
import { ModalComponent, useModal } from "../../../../ui-components/Modal";


export default function DeleteButton({ id, updateEvents }: { id: number, updateEvents: Function }) {

  const { open, handleClose, handleOpen } = useModal()

  return (
    <>
      <ModalComponent open={open} handleClose={handleClose}>
        <Grid sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          <Grid >
            <p>Você realmente deseja apagar este tipo de evento?</p>
          </Grid>
          <Grid sx={{gap: "20px", display: "flex"}}>
            <Button variant="outlined" onClick={handleClose} >Não</Button>
            <Button variant="contained" 
            onClick={() => {
              if(window.confirm("Você realmente deseja apagar este tipo de evento evento? Essa ação pode ser irreversível e afetar outros dados!")){
                deleteEvent(id).then(() => {updateEvents()})
              }
            }}>Sim</Button>
          </Grid>
        </Grid>

      </ModalComponent>
      <IconButton
        title="Pressione 2 vezes para apagar"
        onClick={() => {
          handleOpen()
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
}
