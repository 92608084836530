import React from 'react'
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "100vh",
  overflowY: "scroll",
  height: "70vh"
};


export const useModal = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    handleClose,
    handleOpen,
    open
  }
}

export function ModalComponent({ children, open, handleClose, sx = {} }, props) {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </>
  )
}
