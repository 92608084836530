import React, { useState, useEffect } from "react";
import ThemeContainer from "../theme/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "../components/Login";
import ForgotPassword from "../components/ForgotPassword";
import Signup from "../components/Signup";
import AdminDashboard from "../components/AdminDashboard";
import UserDashboard from "../components/UserDashboard";
import SectionTitle from "../ui-components/SectionTitle";
import Calendar from "react-calendar";
import EventTable from "../components/AdminDashboard/Tables/EventTable/EventTable";
import ExpositorTable from "../components/AdminDashboard/Tables/ExpositorTable/ExpositorTable";
import ComissionsTable from "../components/AdminDashboard/Tables/CommissionsTable/CommissionsTable";
import SubscriptionTable from "../components/AdminDashboard/Tables/SubscriptionTable/SubscriptionTable";
import ReceiptsAdmin from "../components/AdminDashboard/Receipts";
import Gallery from "../components/AdminDashboard/Tables/Gallery";
import GalleryUser from "../components/UserDashboard/Gallery";
import PreSubscriptionTable from "../components/AdminDashboard/Tables/PreSubscriptionTable/PreSubscriptionTable";
import Events from "../components/UserDashboard/Events";
import PreSubscriptions from "../components/UserDashboard/PreSubscriptions";
import Subscriptions from "../components/UserDashboard/Subscriptions";
import Documents from "../components/UserDashboard/Documents";
import DocumentsAdmin from "../components/AdminDashboard/Documents";
import Commissions from "../components/UserDashboard/Comissions";
import Category from "../components/AdminDashboard/Category";
import Welcome from "../components/UserDashboard/Welcome";
import { parseDateLocale, parseDate } from "../utils/parseDate";
import { Routes as Switch, Route } from "react-router-dom";
import { Grid, Paper, TextField, Button } from "@mui/material";
import { useLoginContext } from "../context/login.context";

import "react-calendar/dist/Calendar.css";

import { getPixCode, editPixCode } from "../services/pix.service";

import { getAllEvents } from "../services/event.service";
import Receipts from "../components/UserDashboard/Receipts";
import EventTypes from "../components/AdminDashboard/EventTypes";

const createDateList = (events: any): String[] => {
  const evArr = [];
  events.forEach((event: any) => {
    evArr.push(parseDate(event.starts_at));
    evArr.push(parseDate(event.ends_at));
  });
  return evArr;
};

function EventCalendar() {
  const [events, setEvents] = useState([]);
  const [dates, setDates] = useState([]);

  function syncEvents() {
    getAllEvents().then((eventsArr) => {
      setEvents(eventsArr);
    });
  }

  useEffect(() => {
    setDates(createDateList(events));
  }, [events]);

  useEffect(() => {
    syncEvents();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <SectionTitle title="Calendário de Eventos" />
          <Calendar
            value={new Date()}
            tileClassName={({ date, view }) => {
              if (dates.includes(parseDate(date))) {
                return "highlight";
              }
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

function EventPage() {
  return (
    <>
      <EventCalendar></EventCalendar>
      <EventTable></EventTable>
    </>
  );
}

function PixPageAdmin() {
  const [currentPix, setCurrentPix] = useState(null);
  const [newPix, setNewPix] = useState("");

  useEffect(() => {
    getPixCode().then((x) => {
      setCurrentPix(x.pixcode || "Adicione um código Pix");
    });
  }, []);

  const handleClick = () => {
    if (newPix === "" && newPix === currentPix) {
      alert("Pix inválido ou igual ao anterior");
    } else {
      editPixCode(newPix).then((x) => {
        alert(
          "Pix alterado com sucesso! A partir de agora os valores de comissão e inscrição serão enviadas para esse Código"
        );
        console.log(x.newPix.pixcode || "Adicione um código Pix");

        setCurrentPix(x.newPix.pixcode);
      });
    }
  };

  return (
    <Grid
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {currentPix && (
        <>
          <SectionTitle title="Alterar Chave Pix"></SectionTitle>
          <TextField
            defaultValue={currentPix}
            onChange={(e) => setNewPix(e.target.value)}
          ></TextField>
          <Button onClick={handleClick} variant="contained">
            Alterar
          </Button>
        </>
      )}
    </Grid>
  );
}

export default function Routes() {
  const { isAdmin } = useLoginContext();

  return (
    <>
      <ThemeContainer>
        <CssBaseline />
        <Switch>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="signup" element={<Signup />} />
            {isAdmin ? (
              <Route path="dashboard" element={<AdminDashboard />}>
                <Route path="events" element={<EventPage />} />
                <Route path="expositors" element={<ExpositorTable />} />
                <Route path="subscriptions" element={<SubscriptionTable />} />
                <Route
                  path="pre-subscriptions"
                  element={<PreSubscriptionTable />}
                />
                <Route path="comissions" element={<ComissionsTable />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="documents" element={<DocumentsAdmin />} />
                <Route path="categories" element={<Category />} />
                <Route path="receipts" element={<ReceiptsAdmin />} />
                <Route path="pix" element={<PixPageAdmin />} />
                <Route path="event-type" element={<EventTypes />} />
              </Route>
            ) : (
              <Route path="dashboard" element={<UserDashboard />}>
                <Route index element={<Welcome />} />
                <Route path="events" element={<Events />} />
                <Route
                  path="pre-subscriptions"
                  element={<PreSubscriptions />}
                />
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="documents" element={<Documents />} />
                <Route path="receipts" element={<Receipts />} />
                <Route path="gallery" element={<GalleryUser />} />
                <Route path="commissions" element={<Commissions />} />
              </Route>
            )}
          </Route>
        </Switch>
      </ThemeContainer>
    </>
  );
}
