import { getToken, getUserInfo } from "./auth.service";

const getAllBios = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/bios`).then(x => x.json());
}

const getBio = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/bios/${getUserInfo().id}`).then(x => x.json());
}

const updateBio = (data: any, id: number) => {
  return fetch(`${process.env.REACT_APP_API_URL}/bios/${id}`, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getToken()}`
      },
      method: "PUT",
      body: JSON.stringify(data)
  })
}

const createBio = (data: any) => {
  return fetch(`${process.env.REACT_APP_API_URL}/bios`, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getToken()}`
      },
      method: "POST",
      body: JSON.stringify(data)
  })
}

export {getBio, updateBio, createBio, getAllBios}