import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducers from "./redux/reducers";
import { LoginProvider } from "./context/login.context";

const Root = ({ children }: { children: JSX.Element }) => (
  <BrowserRouter>
    <Provider store={createStore(reducers)}>
      <LoginProvider>
        {children}
      </LoginProvider>
    </Provider>
  </BrowserRouter>
)

export default Root;

