import { useState } from "react";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
} from "@mui/material";
import logo from "../assets/logo-white.png";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import requestLogin from "../services/login.service";
import { login } from "../services/auth.service";
import { useLoginContext } from "../context/login.context";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("Login feito com sucesso!");
  const navigate = useNavigate();

  const { loginToken } = useLoginContext();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    requestLogin(email, password)
      .then((x) => {
        return x.json();
      })
      .then((x) => {
        
        if(x.status === 200) {
          setOpen(true)
        } else {
          if (x.message === "Unauthorized"){
            setMessage("Email inválido!");
            setOpen(true);
          } else if(x.message === 'Authentication failed. Wrong password'){
            setMessage("Senha inválida!");
            setOpen(true);
          } else {
            setMessage("Login deve ser feito com seu email!");
            setOpen(true);
          }
        }

        if (x.status === 200) {
          login(x.accessToken);
          loginToken(x.accessToken)

          localStorage.setItem("userInfo", JSON.stringify(x.user))
          navigate("/dashboard", { replace: true });
        }
      });
  };

  const setEmailInput = (e: any) => {
    setEmail(e.target.value);
  };

  const setPasswordInput = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={logo}
          sx={{
            width: "200px",
            backgroundColor: "#a8518e",
            borderRadius: "50%",
            marginBottom: "1rem",
          }}
        />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Endereço de Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={setEmailInput}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={setPasswordInput}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Manter Login"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Iniciar Sessão
          </Button>
          <Grid container>
            <Grid item xs>
              <RouterLink to="/forgot-password">
                <Link component={"p"} variant="body2">
                  Esqueceu sua senha?
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
          <RouterLink to="/signup">
            <Link component={"p"} variant="body2">
              {"Ainda não tem uma conta? Cadastre-se"}
            </Link>
          </RouterLink>
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={message}
      />
    </Container>
  );
}
