export default function recoverPassword(email: string) {
  return fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { 
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        email
    })
});
}