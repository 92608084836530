import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Grid, Paper, Link, Button } from "@mui/material";
import SectionTitle from "../../../../ui-components/SectionTitle";
import {
  getAllSubscriptions,
  getSubscriptionStatus,
} from "../../../../services/subscriptions.service";
import UpdateButton from "./OperationButtons/UpdateButton";
import { parseDate } from "../../../../utils/parseDate";
import { useAdminStore } from "../../../../context/admin-store";
import { useCategory } from "../../../../context/category-store";
import ViewButton from "./OperationButtons/ViewButton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as XLSX from "xlsx"

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Nome", width: 120 },
  { field: "brand_name", headerName: "Marca", width: 120 },
  {
    field: "instagram_url",
    headerName: "Instagram",
    width: 110,
    renderCell: (param) => <>{param.value}</>,
  },
  { field: "category", headerName: "Categoria", width: 120 },
  { field: "start_date", headerName: "Data Entrada", width: 120 },
  { field: "end_date", headerName: "Data Saida", width: 120 },
  {
    field: "actions",
    headerName: "Ações",
    width: 80,
    renderCell: (param) => <>{param.value}</>,
  },
];
interface PreSubscription {
  id: number;
  user_id: number;
  event_id: number;
  first_time: boolean;
  start_date: Date;
  end_date: Date;
  status_id: number;
  created_at: Date;
}

//nome marca instagram categoria

const createRows = (
  events: PreSubscription[],
  updateSubscriptions: Function,
  users: any,
  categories: any
) => {
  return events.map(
    (
      {
        event_id,
        created_at,
        user_id,
        first_time,
        start_date,
        id,
        end_date,
        status_id,
      },
      i,
      arr
    ) => {
      const user = users.find((user) => user.id === user_id);
      const category = categories.find((cat) => cat.id === user.category_id);
      return {
        id,
        event_id,
        name: user.name,
        brand_name: user.brand_name,
        created_at: parseDate(created_at),
        instagram_url: (
          <Link
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={`https://www.instagram.com/${user.instagram_url.split("@")[1]
              }/`}
          >
            {user.instagram_url}
          </Link>
        ),
        category: category.name,
        user_id,
        first_time: first_time ? "Sim" : "Não",
        start_date: parseDate(start_date),
        end_date: parseDate(end_date),
        status_id: getSubscriptionStatus(status_id),
        actions: (
          <>
            <UpdateButton
              id={id}
              updateSubscriptions={updateSubscriptions}
              sub={arr[i]}
            />
            <ViewButton
              id={id}
              updateEvents={() => { }}
              subscription={arr[i]}
            ></ViewButton>
          </>
        ),
      };
    }
  );
};

export default function PreSubscriptionTable() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [deniedSubscriptions, setDeniedSubscriptions] = useState([]);

  const users = useAdminStore((state) => state.users);
  const categories = useCategory((state) => state.categories);
  const events = useAdminStore((state) => state.events);

  function getSubscriptions() {
    getAllSubscriptions()
      .then((x) => {
        setSubscriptions(x.filter((x: any) => x.status_id === 0));
        setDeniedSubscriptions(x.filter((x: any) => x.status_id === 2));
      });
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  function exportData() {
    const excelsubs = subscriptions.map((obj) => ({...obj, event_name: (events.find(ev => ev.id === obj.event_id))?.name || "EVENTO APAGADO", user_name: (users.find(ev => ev.id === obj.user_id))?.name, extra_table: +obj.extra_table ? "Sim" : "Nao", extra_chair: +obj.extra_chair ? "Sim" : "Nao"  }))
    const worksheet = XLSX.utils.json_to_sheet(excelsubs)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "PreSubscriptions");
    XLSX.utils.sheet_add_aoa(worksheet, [["ID", "Usuario ID", "Evento ID", "Notas", "Tipo Espaco", "Items", "Categoria ID", "Espaco Evento", "Data Inicio", "Data Fim", "Mesa Extra", "Status ID", "Pagamento Efetuado", "Codigo PIX", "Cadeira Extra", "Valor", "Quantidade Mesas", "Quantidade Cadeiras", "Criado Em", "Atualizado Em", "Nome Evento", "Nome Usuario"]], { origin: "A1" });
    XLSX.writeFile(workbook, "PreInscricoes.xlsx", { compression: true });
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <SectionTitle title="Pré-Inscrições" />
        <Button variant="contained" onClick={exportData}>EXPORTAR DADOS COMO EXCEL</Button>
      </div>
      {events.map((event) => (
        <Paper sx={{ p: 2 }}>
          <Accordion key={event.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{event.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container my={3}>
                <Grid item xs={12}>
                  <SectionTitle title={`Evento: ${event.name} ${event.id}`} />
                  <SectionTitle title="Tabela de Pré Inscrições em Avaliação 🔄" />
                  <div style={{ height: 660, width: "100%" }}>
                    <DataGrid
                      rows={createRows(
                        subscriptions.filter(
                          (sub) => sub.event_id === event.id
                        ),
                        getSubscriptions,
                        users,
                        categories
                      )}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      checkboxSelection={false}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={3} my={3}>
                <Grid item xs={12}>
                  <SectionTitle title="Tabela de Pré Inscrições Negadas ❌" />
                  <div style={{ height: 660, width: "100%" }}>
                    <DataGrid
                      rows={createRows(
                        deniedSubscriptions.filter(
                          (sub) => sub.event_id === event.id
                        ),
                        getSubscriptions,
                        users,
                        categories
                      )}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      checkboxSelection={false}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
      ))}
    </>
  );
}
