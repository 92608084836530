import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Button from "@mui/material/Button";
import { ModalComponent, useModal } from '../../../ui-components/Modal';
import { deleteDocuments, getDocuments } from '../../../services/documents.service';
import { getAllEvents } from '../../../services/event.service';
import { parseDate } from '../../../utils/parseDate';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

export default function Documents() {

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [events, setEvents] = React.useState([])  
  const [documents, setDocuments] = React.useState([])
  const [currentEvent, setCurrentEvent] = React.useState(0) 
  const { open, handleClose, handleOpen } = useModal();

  function getDocs(){
    getDocuments()
      .then((data) => {
        setDocuments(data)
      })
  }

  React.useEffect(() => {
    getDocs();
  }, [])

  React.useEffect(() => {
    getAllEvents()
      .then((data) => {
        setEvents(data)
      })
  }, [])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleDelete = async (id: number) => {
      await deleteDocuments(id)
      window.location.reload()
  }

  const handleFileAdd = () => {
    setTimeout(() => {handleClose()}, 1000); 
    setTimeout(() => {getDocs()}, 3000);
  }

  return (
    <>
      <ModalComponent open={open} handleClose={handleClose}>
      <form onSubmit={handleFileAdd} action={`${process.env.REACT_APP_API_URL}/documents`} method="post" encType="multipart/form-data" target='hiddenFrame'>
        <input type="file" name={currentEvent.toString()} multiple/>
        <Button fullWidth={true} type="submit" sx={{ marginTop: 4 }} variant="contained">Fazer Upload de Arquivo</Button>
      </form>
      </ModalComponent>
      <div>
      {events && events.map((event,i) => (
        <Accordion key={event.id} expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {event.name}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{`${parseDate(event.starts_at)} ate ${parseDate(event.ends_at)}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ImageList cols={4}>
            {documents && documents.filter(doc => doc.event_id === event.id).map((item) => (
              <a key={item.id} target={"_blank"} rel="noreferrer" href={item.url}>
                <ImageListItem>
                  <img
                    src={item.file_type.includes('image/')? item.url : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAbFBMVEX///8AAABLS0vc3NwwMDDo6Oi0tLTCwsJ0dHSGhoY9PT0/Pz/09PQcHBx4eHg4ODhVVVUUFBTIyMiXl5eqqqqhoaFtbW3S0tLOzs4uLi7i4uJlZWUlJSVfX19QUFCsrKwYGBiAgIBFRUW6urrFevtnAAADDElEQVR4nO3d61IaQRRF4eGiEMMtQAAxaozv/46JSf50M1xK9j5ntNb3ANOs6p7pES27aQAAAAAAAAAAAD685WrWnw9F7vvZObXlQ0/sLjupsP6m7utW4n5o6OtS4ndP3x9fs9P+2dgCO5K4MAZ2ItE5g2/S70XfPdiRxIE9MHuhmraJ7iTeRgSmJv6IKcxLHAUF5iU+1h/k6XV7c51jz+akxOpTTLfXX/LonZ2SuKs+xERwzePProx9sVpRghk8+XROSBwXH+BJcs1T+0/8Qn0qxn+WXPPkDhs+i339Ij3zDhE9i2XhQHLNM29JwbOYUBg8ixmFsbPoLxzPc2fRX/ilafmWMnAWIwonLT++xCVGFDaTzFkMKWwm07zEmMLWxKDHTVBh4ixGFebNYlhhM/mZM4txhVkLNbCwddOYS0Y8JbKwdRbtiaGFKVt/bGHGvRhcmDCL0YXxm0Z4YXhifGHT3Icm+gvvZuPS7OBXJdbElO9p2tj2xc4U9oaSsQ91p9C1UDtU2HuUjF7rUqHnbzUpfA8KKdSi8D0opFDLUXgzutT6gxZebv/pCwcUClDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaEChV4UKlDoRaHC5y8sT3jcWcY4rjwFVXOCZq08N35tGeO48j9DLixjvASsk+N+FaO/WMaozpKNXabVUb2eFbQtB5GcB3ypSTW25hTUA9UoijOdL7SrD2QxjfNQDdNbXH0u9yW2t/164I2pcFkPlMb2DGg5EyWF63yLpnnNTvtvZCtsWs56TeA8KXBwfvgAe2NhveunML8vbrL7ejNvYP3+Hc9zwkwhdxbH/sDcezHoZ7ZB1s4/tz5FC+uWowntps9hfW9GB6/hZptlaN9fy9WsPx/6zfuzVfS3QgAAAAAAAAAAAAa/ARXQN87ZDmHrAAAAAElFTkSuQmCC'}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.name}
                    subtitle={item['file_type']}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                        onClick={(e) => { 
                          e.preventDefault(); 
                          if(window.confirm("Você realmente deseja excluir este arquivo? Esta ação pode ser irreversível!")){
                            handleDelete(item.id)
                          
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              </a>
            ))}
          </ImageList>
          <Button fullWidth={true} onClick={() => {handleOpen(); setCurrentEvent(event.id)}} variant="contained">Adicionar Arquivo</Button>
        </AccordionDetails>
      </Accordion>
      ))}
      <iframe title="hiddenFrame" name="hiddenFrame" width="0" height="0" style={{display: "none"}}></iframe>
      </div>
    </>
  );
}


