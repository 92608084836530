import React from "react";
import { Typography } from "@mui/material";

export default function ConfirmSignup() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Confirmando seu cadastro no nosso sistema.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Seus dados foram preenchidos corretamente? Agora é só apertar o botão de
        próximo e te enviaremos um email para confirmação.
      </Typography>
    </>
  );
}
