import { SAVE_SIGNUP_DATA } from "./types";
import { MAKE_SIGNUP_REQUEST } from "./types";

export function saveSignupData(data: any) {
    return {
        type: SAVE_SIGNUP_DATA,
        payload: data
    };
}

export function makeSignupRequest(){
    return {
        type: MAKE_SIGNUP_REQUEST
    };
}