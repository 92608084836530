import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import TextField from "@mui/material/TextField";
import { Grid, Paper } from "@mui/material";
import SectionTitle from "../../ui-components/SectionTitle";
import Button from "@mui/material/Button";
import { getAllPics } from "../../services/gallery.service";
import { ModalComponent, useModal } from '../../ui-components/Modal';
import { createBio, getBio, updateBio } from "../../services/bio.service";
import { getUserInfo } from "../../services/auth.service";

export default function Gallery() {

  const [gallery, setGallery] = React.useState([]);
  const [bio, setBio] = React.useState("");
  const [bios, setBios] = React.useState([]);
  const { open, handleClose, handleOpen } = useModal();

  React.useEffect(() => {
    getAllPics().then(x => {
      setGallery(x)
    })
  }, []);

  React.useEffect(() => {
    getBio().then(x => {
      setBios(x)
    })
  }, []);

  React.useEffect(() => {
     bios.length > 0 && setBio(bios[0].bio)
     console.log(bios)
  }, [bios]);

  const handleFileAdd = () => {
    setTimeout(() => {handleClose()}, 1000); 
    setTimeout(() => {getAllPics().then(x => {
      setGallery(x)
    })}, 3000);
  }

  const handleSaveBio = () => {
    const selectedBio = bios[0];
    
    const data = {
      bio,
      user_id: +getUserInfo().id
    }

    if(selectedBio) {
      console.log("alterar");
      updateBio(data,selectedBio.id).then(x => alert("Bio alterada com sucesso!"))
    } else {
      console.log("criar");
      createBio(data).then(x => alert("Bio criada com sucesso!"))
    }
    
  }

  return (
    <Grid container spacing={3} my={3}>
      <ModalComponent open={open} handleClose={handleClose}>
      <form onSubmit={handleFileAdd} action={`${process.env.REACT_APP_API_URL}/gallery`} method="post" encType="multipart/form-data" target='hiddenFrame'>
        <input type="file" name={JSON.parse(localStorage.getItem("userInfo")).id} multiple/>
        <Button fullWidth={true} type="submit" sx={{ marginTop: 4 }} variant="contained">Fazer Upload de Arquivo</Button>
      </form>
      </ModalComponent>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid display={"flex"} justifyContent="space-between">
          <SectionTitle title="Galeria de Imagens e Videos"/>
          <Button onClick={handleOpen} variant="contained">Adicionar Imagem ou Video</Button>
          </Grid>
          <ImageList>
            {gallery && gallery.filter(x => x.user_id === JSON.parse(localStorage.getItem("userInfo")).id).map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.url}`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Paper>
        <Paper sx={{ p: 2, mt: 2 }}>
        <Grid display={"flex"} justifyContent="space-between">
          <SectionTitle title="Bio"/>
          <Button onClick={handleSaveBio} variant="contained">Salvar Bio</Button>
          </Grid>
        <TextField
          id="filled-multiline-flexible"
          label=""
          multiline
          rows={50}
          fullWidth
          onChange={(e) => setBio(e.target.value)}
          value={bio}
        />
        </Paper>
      </Grid>
      <iframe title="hiddenFrame" name="hiddenFrame" width="0" height="0" style={{display: "none"}}></iframe>

    </Grid>
  );
}

