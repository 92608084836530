import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { updateSubscription } from "../../../../../services/subscriptions.service";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface PreSubscription {
  id: number;
  user_id: number;
  event_id: number;
  first_time: boolean;
  start_date: Date;
  end_date: Date;
  status_id: number;
  created_at: Date;
}

export default function DeleteButton({ id, updateSubscriptions, sub }: { id: number, updateSubscriptions: Function, sub: PreSubscription }) {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData: any = Object.fromEntries(new FormData(e.target).entries())
    formData.status_id = parseInt(formData.status_id)
    updateSubscription(formData, id)
    handleClose()
    updateSubscriptions()
  }

  useEffect(() => {
    updateSubscriptions()
    return () => {
      updateSubscriptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editando as informações da Pré-inscrição :
          </Typography>

          <form onSubmit={handleSubmit}>
            <p>Status da Pre Inscrição:
              <select name="status_id" defaultValue={sub.status_id}>
                <option value={0}>Em Análise</option>
                <option value={1}>Aceito</option>
                <option value={2}>Recusado</option>
              </select></p>
            <Button onClick={handleClose}>Cancelar</Button> <Button type="submit">Alterar</Button>
          </form>
        </Box>
      </Modal>
      <IconButton
        onClick={() => {
          handleOpen();
          updateSubscriptions();
        }}
      >
        <EditIcon />
      </IconButton>
    </>
  );
}
