import React from 'react'
import { Paper, TextField, ListItemButton, ListItemIcon, ListItemText, Button, List, Grid, ListItem } from "@mui/material"
import SectionTitle from '../../ui-components/SectionTitle'
import { createCategory, deleteCategory, getAllCategories } from '../../services/category.service'
import {Delete} from "@mui/icons-material"

export default function Category() {

  const [name, setName] = React.useState("")
  const [categories, setCategories] = React.useState([])

  React.useEffect(() => {
    getAllCategories().then(x => setCategories(x))
  }, [])

  function handleClick() {
    const obj = { name }

    createCategory(obj).then(() => {
      getAllCategories().then(x => setCategories(x))
    })
   

  }

  return (
    <>
      <>
        <Paper sx={{p: 4, maxWidth: "400px " }}>
          <SectionTitle title='Criar categoria' />
          <Grid my={2} >
            <TextField
              label="Nome da categoria"
              variant='standard'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            </Grid>
            <Grid>
            <Button onClick={handleClick} variant="contained">Criar Categoria +</Button>
            </Grid>
        </Paper>

        <Paper sx={{ maxWidth: "400px", p: 4 }}>
          <SectionTitle title='Todas as categorias'></SectionTitle>
          <List>
            {categories && categories.filter(x => x.active).map(x => (
              <ListItem key={x.id}>
                <ListItemText primary={x.name} secondary={x.id} />
                <ListItemIcon>
                  <ListItemButton onClick={() => { 
                    
                    if(window.confirm("Você realmente deseja apagar esta categoria")){
                      deleteCategory(x.id).then(() => {
                        getAllCategories().then(x => setCategories(x))
                    })
                    } else {
                      
                    }
                    }}>
                  <Delete />
                  </ListItemButton>
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </Paper>
      </>

    </>
  )
}
