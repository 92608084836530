import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { updateSubscription } from "../../../../../services/subscriptions.service";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { updateCommission } from "../../../../../services/commissions.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Commission {
  id: number;
  user_id: number;
  subscription_id: number;
  amount: number;
  is_paid: any;
  created_at: Date;
}

export default function UpdateButton({ id, updateCommisions, sub }: { id: number, updateCommisions: Function, sub: Commission }) {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData: any = Object.fromEntries(new FormData(e.target).entries())
    console.log(formData);
    formData.is_paid = Boolean(+formData.is_paid)
    console.log(formData);
    
    updateCommission(formData, id).then(() => {
      alert("Comissão alterada com sucesso!")
      updateCommisions()
    })
    handleClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editando as informações da Comissão :
          </Typography>

          <form onSubmit={handleSubmit}>
            <p>Pagamento Efetuado:
              <select name="is_paid" defaultValue={+sub.is_paid}>
                <option value={+true}>Sim</option>
                <option value={+false}>Não</option>
              </select></p>
            <Button onClick={handleClose}>Cancelar</Button> <Button type="submit">Alterar</Button>
          </form>
        </Box>
      </Modal>
      <IconButton
        onClick={() => {
          handleOpen();
          updateCommisions();
        }}
      >
        <EditIcon />
      </IconButton>
    </>
  );
}
