import { getToken } from "./auth.service";
import { getUserInfo } from "./auth.service";

const getAllExpositors = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "GET",
    })
}

const getExpositorInfo = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/users/${getUserInfo().id}/profile`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "GET",
    }).then((x) => x.json())
}

const updateExpositorInfo = (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}/users/${getUserInfo().id}/profile`, {
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "PUT",
    }).then((x) => x.json())
}

const updatePassword = (password) => {
    return fetch(`${process.env.REACT_APP_API_URL}/auth/change-password`, {
        body: JSON.stringify({
            email: getUserInfo().email,
            password
        }),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "POST",
    }).then((x) => x.json())
}

const updateOverdue = (overdue: boolean, userId: number) => {
    return fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/overdue`, {
        body: JSON.stringify({
            overdue
        }),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "PUT",
    }).then((x) => x.json())
}

export { getAllExpositors, getExpositorInfo, updateExpositorInfo, updatePassword, updateOverdue }