import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, Grid, Link, Paper } from "@mui/material";
import SectionTitle from "../../../../ui-components/SectionTitle";
import { getAllExpositors } from "../../../../services/expositor.service";
import ViewButton from "./OperationButtons/ViewButton";
import { parseDate } from "../../../../utils/parseDate";
import { useAdminStore } from "../../../../context/admin-store";
import { useCategory } from "../../../../context/category-store";
import BanButton from "./OperationButtons/BanButton";
import * as XLSX from "xlsx"

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "name", headerName: "Nome", width: 200, renderCell: (param) => <>{param.value}</> },
  { field: "brand_name", headerName: "Marca", width: 140 },
  { field: "instagram_url", headerName: "Instagram", width: 110, renderCell: (param) => <>{param.value}</> },
  { field: "category", headerName: "Categoria", width: 160 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "is_admin", headerName: "Admin", width: 100, renderCell: (param) => <>{param.value}</> },
  { field: "created_at", headerName: "Criado em", width: 120 },
  { field: "overdue", headerName: "Inadimplente", width: 120 },
  { field: "actions", headerName: "Ações", width: 90, renderCell: (param) => <>{param.value}</> },
];
interface Expositor {
  name: string;
  email: string;
  created_at: Date;
  is_admin: boolean;
  full_address: string;
  instagram_url: string;
  brand_name: string;
  category_id: number;
  id: number;
  address: string;
  phone: string;
  brand_description: string;
  state: string;
  city: string;
  overdue: boolean;
}

const createRows = (events: Expositor[], updateExpositors: Function, categories: any) => {
  return events.map(
    (
      {
        name,
        created_at,
        brand_name,
        email,
        instagram_url,
        id,
        is_admin,
        category_id,
        overdue
      },
      i,
      arr
    ) => {
      return {
        name: <p style={overdue ? {backgroundColor: "red", padding: "4px 8px", borderRadius: "20px"} : {}}>{name}</p>,
        email,
        created_at: parseDate(created_at),
        is_admin: is_admin ? "Sim" : "Não",
        instagram_url: <Link target={"_blank"} rel={"noopener noreferrer"} href={`https://www.instagram.com/${instagram_url.split("@")[1]}/`}>{instagram_url}</Link>,
        category: categories.find(cat => cat.id === category_id).name || "Não encontrado",
        brand_name,
        overdue: overdue ? "Sim" : "Não",
        id,
        actions: (
          <>
            <ViewButton id={id} updateEvents={updateExpositors} expositor={arr[i]} />
            <BanButton id={id} updateEvents={updateExpositors} expositor={arr[i]} ></BanButton>
          </>
        ),
      }
    }
  );
};

export default function ExpositorTable() {
  const data = useAdminStore(state => state.fetch);
  const users = useAdminStore(state => state.users);
  const category = useCategory(state => state.categories);
  const categoryFetch = useCategory(state => state.fetch);

  useEffect(() => {
    data()
    categoryFetch()
  }, []);


  function exportData() {
    const worksheet = XLSX.utils.json_to_sheet(users)
    const workbook = XLSX.utils.book_new();
    const max_width = users.reduce((w, r) => Math.max(w, r.name.length), 10);
    worksheet["!cols"] = [ { wch: max_width } ];
    worksheet['!rows'] = [ {hpx: 15} ];
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.utils.sheet_add_aoa(worksheet, [["ID", "Nome", "Email", "Senha", "Inadimplente", "Aniversario", "Admin", "Email Verificado", "CEP", "Cidade", "Estado", "Bairro", "Endereco", "Endereco Numero", "Complemento", "Telefone", "CPF", "CNPJ", "Instagram URL", "Nome da Marca", "Descricao Marca", "Categoria ID","Items da Marca", "Criado Em", "Atualizado Em"]], { origin: "A1" });
    XLSX.writeFile(workbook, "Expositores.xlsx", { compression: true });
  }

  return (
    <>
      {category && users && (
        <Grid container spacing={3} my={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <SectionTitle title="Expositores" />
            <Button variant="contained" onClick={exportData}>EXPORTAR DADOS COMO EXCEL</Button>
            </div>
            <div style={{ height: 660, width: "100%" }}>
              <DataGrid
                rows={createRows(users, data, category)}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection={false}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
      )}
    </>
  );
}
