import * as React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from "@mui/icons-material/People";
import EventIcon from "@mui/icons-material/Event";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Link } from "react-router-dom";
import CollectionsIcon from '@mui/icons-material/Collections';


export const mainListItems = (
  <React.Fragment>

    <Link to="events">
      <ListItemButton>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Eventos" />
      </ListItemButton>
    </Link>

    <Link to="expositors">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Expositores" />
      </ListItemButton>
    </Link>

    <Link to="pre-subscriptions">
      <ListItemButton>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="Pré Inscrições" />
      </ListItemButton>
    </Link>

    <Link to="subscriptions">
      <ListItemButton>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="Inscrições" />
      </ListItemButton>
    </Link>

    <Link to="comissions">
      <ListItemButton>
        <ListItemIcon>
          <PointOfSaleIcon />
        </ListItemIcon>
        <ListItemText primary="Comissões" />
      </ListItemButton>
    </Link>

    <Link to="documents">
      <ListItemButton>
        <ListItemIcon>
          <FileCopyIcon></FileCopyIcon>
        </ListItemIcon>
        <ListItemText primary="Documentos" />
      </ListItemButton>
    </Link>

    <Link to="receipts">
      <ListItemButton>
        <ListItemIcon>
        <ReceiptLongIcon></ReceiptLongIcon>
        </ListItemIcon>
        <ListItemText primary="Comprovantes" />
      </ListItemButton>
    </Link>

    <Link to="gallery">
      <ListItemButton>
        <ListItemIcon>
          <CollectionsIcon></CollectionsIcon>
        </ListItemIcon>
        <ListItemText primary="Galeria" />
      </ListItemButton>
    </Link>

    <Link to="event-type">
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Tipos de Evento" />
    </ListItemButton>
    </Link>

  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Configurações Gerais
    </ListSubheader>

    <Link to="categories">
    <ListItemButton>
      <ListItemIcon>
        <AddBoxIcon />
      </ListItemIcon>
      <ListItemText primary="Adicionar Categoria" />
    </ListItemButton>
    </Link>

    <Link to="pix">
    <ListItemButton>
      <ListItemIcon>
        <AddBoxIcon />
      </ListItemIcon>
      <ListItemText primary="Alterar chave PIX" />
    </ListItemButton>
    </Link>

  </React.Fragment>
);
