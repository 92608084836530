import React, { useEffect, useRef, useState } from "react";
import { TextField, Typography, Grid } from "@mui/material";

function debounce(func, wait, immediate) {
  let timeout;

  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};


export default function UserForm() {

  const [validEmail, setValidEmail] = useState(true);
  const emailValidMessage = validEmail ? "" : "❌ Este email já foi utilizado em nosso sistema! ❌";

  const password = useRef(null)
  const confirmPassword = useRef(null)

  const rua = useRef(null);
  const bairro = useRef(null);
  const cidade = useRef(null);
  const estado = useRef(null);

  const email = useRef(null);


  const [cep, setCep] = useState('21051360')

  useEffect(() => {

    const senha = password.current.querySelector('input');
    const confirmaSenha = confirmPassword.current.querySelector('input');

    const emailEl = email.current.querySelector('input') 

    function validatePassword() {
      if (senha.value !== confirmaSenha.value) {
        confirmaSenha.setCustomValidity("As senhas não são idênticas");
      } else {
        confirmaSenha.setCustomValidity('');
      }
    }

    async function validateEmail() {
      if((await (await (fetch(`${process.env.REACT_APP_API_URL}/users/checkemail?email=${emailEl.value}`))).json()).emailExists){
        setValidEmail(false);
        emailEl.setCustomValidity('Esse email já existe em nosso sistema! Utilize outro email!')
      } else {
        emailEl.setCustomValidity('');
        setValidEmail(true);
      }
    }

    senha.onchange = validatePassword
    confirmaSenha.onkeyup = validatePassword

    emailEl.onkeyup = debounce(validateEmail, 250)

  }, [])

  function debounce(func, timeout = 2000) {
    let timer;
    return () => {
      clearTimeout(timer)
      timer = setTimeout(func, timeout)
    }
  }

  const getData = async (cep) => {
    const data = await (await fetch(`https://viacep.com.br/ws/${cep}/json`)).json()
    console.log(data);
    
    if (!data.erro) {
      rua.current.querySelector('input').value = data.logradouro;
      cidade.current.querySelector('input').value = data.localidade;
      estado.current.querySelector('input').value = data.uf;
      bairro.current.querySelector('input').value = data.bairro;
    }
  };

  const teste = (cep) => debounce(getData(cep))

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Adicionando seus dados de usuário
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label="Nome"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="standard"
            ref={email}
          />
          {emailValidMessage}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="password"
            name="password"
            label="Senha"
            type={"password"}
            ref={password}
            fullWidth
            autoComplete="password"
            variant="standard"
            InputProps={{ inputProps: { minLength: 6 } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="confirm-password"
            label="Confirme sua Senha"
            type={"password"}
            ref={confirmPassword}
            fullWidth
            autoComplete="confirm-password"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="birthday"
            name="birthday"
            label="Data de Nascimento"
            type={"date"}
            fullWidth
            defaultValue={"2000-01-01"}
            autoComplete="birthday"
            variant="standard"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zipcode"
            name="zipcode"
            label="CEP"
            fullWidth
            InputProps={{ inputProps: { minLength: 8, maxLength: 8 } }}
            autoComplete="shipping postal-code"
            variant="standard"
            onChange={(e) => { setCep(e.target.value); teste(e.target.value); }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="Cidade"
            fullWidth
            ref={cidade}
            autoComplete="shipping address-level2"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="state"
            name="state"
            label="Estado"
            fullWidth
            ref={estado}
            variant="standard"
            type={"text"}
            InputProps={{ inputProps: { minLength: 2, maxLength: 2 } }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="district"
            name="district"
            label="Bairro"
            fullWidth
            autoComplete="shipping district"
            variant="standard"
            ref={bairro}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="address"
            name="address"
            label="Rua"
            ref={rua}
            fullWidth
            autoComplete="shipping street"
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="address_number"
            name="address_number"
            label="Número"
            fullWidth
            autoComplete="shipping address_number"
            variant="standard"
            type={"number"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address_complement"
            name="address_complement"
            label="Complemento"
            autoComplete="address_complement"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Telefone celular com DDD"
            autoComplete="phone"
            variant="standard"
            placeholder="11988888888"
            fullWidth
            InputProps={{ inputProps: { minLength: 11, maxLength: 11 } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="cpf"
            name="cpf"
            label="CPF (Não obrigatório)"
            autoComplete="cpf"
            variant="standard"
            type={"number"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="cnpj"
            name="cnpj"
            label="CNPJ (Não obrigatório)"
            autoComplete="cnpj"
            variant="standard"
            type={"number"}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
