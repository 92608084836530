import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import { createEvent, getAllEvents } from "../../../../services/event.service";
import { parseDate } from "../../../../utils/parseDate";
import SectionTitle from "../../../../ui-components/SectionTitle";
import CurrencyInput from "react-currency-input-field";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import UpdateButton from "./OperationButtons/UpdateButton";
import DeleteButton from "./OperationButtons/DeleteButton";
import ViewButton from "./OperationButtons/ViewButton";
import CachedIcon from '@mui/icons-material/Cached';

import { TextField } from "@mui/material";
import { useAdminStore } from "../../../../context/admin-store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Nome", width: 260 },
  { field: "starts_at", headerName: "Data Ínicio", width: 120 },
  { field: "ends_at", headerName: "Data Termino", width: 120 },
  { field: "active", headerName: "Ativo", width: 100 },
  { field: "created_at", headerName: "Criado em", width: 120 },
  {
    field: "actions",
    headerName: "Ações",
    width: 160,
    renderCell: (param) => <>{param.value}</>,
  },
];
interface Event {
  active: boolean;
  cover: string;
  created_at: Date;
  description: string;
  ends_at: Date;
  name: string;
  updated_at: Date;
  starts_at: Date;
  id: number;
  limitdate: Date;
  event_data: any;
  event_address: string;
}

const createRows = (events: Event[], updateEvents: Function) => {
  return events.map(
    ({ active, created_at, ends_at, name, starts_at, id }, i, arr) => ({
      active: active ? "Sim" : "Não",
      created_at: parseDate(created_at),
      id,
      name,
      starts_at: parseDate(starts_at),
      ends_at: parseDate(ends_at),
      actions: (
        <>
          <DeleteButton id={id} updateEvents={updateEvents} />
          <UpdateButton id={id} updateEvents={updateEvents} event={arr[i]} />
          <ViewButton id={id} updateEvents={updateEvents} event={arr[i]} />
        </>
      ),
    })
  );
};

export default function EventTable() {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = React.useState(false);

  const eventTypes = useAdminStore(state => state.eventTypes)

  console.log(eventTypes);
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function syncEvents() {
    getAllEvents().then((eventsArr) => {
      setEvents(eventsArr);
    });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    let data: any = Object.fromEntries(new FormData(e.target).entries());
    const { name, description, starts_at, ends_at, limitdate, event_address } = data;

    const reqObj = {
      name, description, starts_at, ends_at, limitdate, event_address, event_data: JSON.stringify({
        eventType_id: data.eventTypes_id
      })
    }

    if (Date.parse(data.starts_at) > Date.parse(data.ends_at)) {
      alert("Data de início não pode ser maior que a data de término.")
    } else {
      createEvent(reqObj).then(() => {
        syncEvents()
        handleClose()
      })
    }
  }

  useEffect(() => {
    syncEvents();

    return () => {
      syncEvents();
    };
  }, []);

  useEffect(() => {
    syncEvents();
  }, [open]);


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Criar novo Evento
          </Typography>
          <form onSubmit={handleSubmit} style={{ gap: "3px" }}>
            <>
              <TextField
                required
                id="name"
                name="name"
                label="Nome do Evento"
                fullWidth
                autoComplete="given-name"
                variant="standard"
              />

              <TextField
                required
                id="event_address"
                name="event_address"
                label="Endereço completo do evento"
                fullWidth
                autoComplete="given-name"
                variant="standard"
              />


              <FormControl
              variant="standard"
              sx={{
                width: "100%",
                gap: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputLabel id="demo-simple-select-filled-label">
                Selecione o Modelo de Evento 
              </InputLabel>
              <Select
                required
                labelId="eventTypes_id"
                id="eventTypes_id"
                name="eventTypes_id"
                fullWidth
              >
                {eventTypes &&
                  eventTypes
                    .map((event) => {                   
                      return (
                        <MenuItem key={event.id} value={event.id}>
                          {event.event_data.name}
                        </MenuItem>
                      )
                    })}
              </Select>
            </FormControl>

              <Typography id="modal-modal-title" sx={{ m: 4, textAlign: "center" }} variant="h6" component="h2">
                Informação Geral do Evento
              </Typography>

              <TextField
                required
                id="description"
                name="description"
                label="Descrição"
                fullWidth
                autoComplete="description"
                variant="standard"
              />

              <TextField
                required
                id="starts_at"
                name="starts_at"
                type={"date"}
                defaultValue={"2022-01-01"}
                label="Data de Início"
                fullWidth
                autoComplete="cover"
                variant="standard"
              />

              <TextField
                required
                id="ends_at"
                name="ends_at"
                type={"date"}
                defaultValue={"2022-01-01"}
                label="Data de Fim"
                fullWidth
                autoComplete="cover"
                variant="standard"
              />

              <TextField
                required
                id="limitdate"
                name="limitdate"
                type={"date"}
                defaultValue={"2022-01-01"}
                label="Data para limite de pagamento"
                fullWidth
                variant="standard"
              />

              <Button type={"submit"} sx={{ m: 2 }}>Criar Evento</Button>
            </>
          </form>
        </Box>
      </Modal>
      <Grid container spacing={3} my={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
              <SectionTitle title="Tabela de Eventos" />
              <div>
                <Button sx={{ m: 1 }} onClick={handleOpen} variant="contained">
                  Criar novo Evento +
                </Button>
                <Button sx={{ m: 1 }} onClick={() => { syncEvents() }} variant="outlined">
                  <CachedIcon></CachedIcon>
                </Button>
              </div>
            </Grid>
            <div style={{ height: 660, width: "100%" }}>
              {events && (
                <DataGrid
                  rows={createRows(events, syncEvents)}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection={false}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
