import { getToken } from "./auth.service";

const getAllSubscriptions = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "GET",
    }).then(x => x.json()).then(x => x)
}

const updateSubscription = (data: any, id: number) => {
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "PATCH",
        body: JSON.stringify(data)
    })
}

function getSubscriptionStatus(id: number) {
    const status: any = {
        0: "Em Análise",
        1: "Aceito",
        2: "Recusado"
    }
    return status[id.toString()]
}

function deleteSubscription(id: number){
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "DELETE",
    })
}

export { getAllSubscriptions, getSubscriptionStatus, updateSubscription, deleteSubscription }