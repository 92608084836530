import React from "react";
import { IconButton, Link } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { updateOverdue } from "../../../../../services/expositor.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Expositor {
  name: string;
  email: string;
  created_at: Date;
  is_admin: boolean;
  full_address: string;
  instagram_url: string;
  brand_name: string;
  address: string;
  id: number;
  phone: string;
  brand_description: string;
  state: string;
  city: string;
}

export default function BanButton({
  id,
  updateEvents,
  expositor,
}: {
  id: number;
  updateEvents: Function;
  expositor: Expositor;
}) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () =>
    updateOverdue(data, expositor.id).then(() => {
      console.log(data);

      alert("Alterado estado de inadimplência!");
      handleClose();
      updateEvents();
    });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Deseja marcar este usuário como inadimplente?
          </Typography>

          <p>
            Definir usuário como inadimplente:
            <select
              name="status_id"
              onChange={(e) => {console.log(e.target.value); setData(!!+e.target.value)}}
            >
              <option disabled selected> -- Selecione o estado -- </option>
              <option value={0}>Não</option>
              <option value={1}>Sim</option>
            </select>
          </p>

          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Alterar
          </Button>
        </Box>
      </Modal>
      <IconButton title="Pressione 2 vezes para ver mais" onClick={handleOpen}>
        <BlockIcon />
      </IconButton>
    </>
  );
}
