import React, { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";
import { getAllCategories } from "../../services/category.service";

export default function BrandForm() {
  const [age, setAge] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories().then(x => setCategories(x.sort((a, b) => a.name.localeCompare(b.name))))
  }, [])
  
  useEffect(() =>{ console.log(categories);
   }, [categories]);

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Adicionando os dados da sua Marca
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="brand_name"
            name="brand_name"
            label="Nome da Marca"
            fullWidth
            autoComplete="brand_name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="instagram_url"
            name="instagram_url"
            label="Instagram"
            fullWidth
            autoComplete="instagram_url"
            variant="standard"
            onChange={(e) => {
              if(e.target.value[0] !== "@"){
                e.target.value = "@" + e.target.value
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="brand_description"
            name="brand_description"
            label="Descrição da Marca"
            rows={4}
            multiline
            fullWidth
            placeholder="Descreva sua marca aqui"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="brand_items"
            label="Itens da sua marca"
            name="brand_items"
            rows={3}
            multiline
            fullWidth
            placeholder="Liste um a um os itens que
            você produz e pretende levar
            na Feira do Bem."
            variant="standard"
          />
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-filled-label">
                Categoria da Marca
              </InputLabel>
              <Select
                required
                labelId="category_id"
                id="category_id"
                name="category_id"
                fullWidth
                value={age}
                onChange={handleChange}
              >
                {categories && categories.filter(x => x.active).map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
