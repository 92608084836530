import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Paper, Grid, Link, Typography, Button } from "@mui/material";
import SectionTitle from "../../../ui-components/SectionTitle";
import { parseDateLocale, parseDate } from "../../../utils/parseDate";
import { getAllEvents } from "../../../services/event.service";
import { ModalComponent, useModal } from "../../../ui-components/Modal";
import { createSubscription, getUserOnly } from "../../../services/expositor/subscriptions.service";
import CreateSubscriptionForm from "./CreateSubscriptionForm";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const createDateList = (events: any): String[] => {
  const evArr = [];
  events.forEach((event: any) => {
    evArr.push(parseDate(event.starts_at));
    evArr.push(parseDate(event.ends_at));
  });
  return evArr;
};

export default function Events() {
  const [events, setEvents] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState<any>({})
  const [selectedDate, setSelectedDate] = useState<any>();
  const [open2, setOpen2] = useState(false);
  const [message, setMessage] = useState(
    "Pré inscrição criada com sucesso, fique de olho no seu email!"
  );
  const [userSubscriptions, setUserSubscriptions] = React.useState([]);

  async function syncSubscriptions() {
    setUserSubscriptions(await getUserOnly());
  }

  React.useEffect(() => {
    syncSubscriptions();
  }, []);

  const { open, handleOpen, handleClose } = useModal();

  async function handleSubmit(e: any) {
    e.preventDefault();

    const data: any = Object.fromEntries(new FormData(e.target).entries());
    const user_id = JSON.parse(localStorage.getItem("userInfo")).id;

    const newData: any = data;

    newData.user_id = parseInt(user_id);
    newData.event_id = parseInt(data.event_id);
    newData.category_id = parseInt(data.category_id);
    newData.event_space = parseInt(data.event_space);
    newData.extra_table = parseInt(data.extra_table);

    const req = await createSubscription(newData);
    handleClose();
  }

  function syncEvents() {
    getAllEvents().then((eventsArr) => {
      setEvents(eventsArr.filter(x => x.active));
    });
  }

  useEffect(() => {
    syncEvents();
  }, []);

  useEffect(() => {
    setDates(createDateList(events));
  }, [events]);

  useEffect(() => {
    const event = events.find((x) => parseDate(x.starts_at) === selectedDate || parseDate(x.ends_at) === selectedDate ) 
    setSelectedEvent(event)
  }, [selectedDate]);

  return (
    <>
      <ModalComponent
        open={open}
        handleClose={handleClose}
        sx={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <SectionTitle title="Criar inscrição para evento:" />
        <CreateSubscriptionForm
          date={selectedDate}
          events={events}
          handleClose={handleClose}
          event={selectedEvent}
        />
      </ModalComponent>

      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Paper sx={{ p: 2 }}>
            <SectionTitle title="Calendário de Eventos" />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Calendar
                showNavigation={true}
                value={new Date()}
                tileClassName={({ date, view }) => {
                  if (dates.includes(parseDate(date))) {
                    return "highlight";
                  }
                }}
                onClickDay={(value, event) => {
                  const formattedDate = parseDate(value) 
                  if (dates.includes(formattedDate)) {
                    setSelectedDate(formattedDate);
                    if (!!userSubscriptions.find((sub) => sub.event_id === selectedEvent.id && sub.status_id != 2)) {
                      alert("Você já está inscrito nesse evento!")
                      return
                    }
                    handleOpen();
                  }
                }}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper sx={{ p: 2 }}>
            <SectionTitle title="Evento Próximo" />
            <Typography component="p" variant="h4">
              {events.length && events[0].name}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Ínicio:{" "}
              {events.length && parseDateLocale(events[0].starts_at)}
              <br />
              Fim:{" "}
              {events.length && parseDateLocale(events[0].ends_at)}
            </Typography>
            <div>
              <Link
                color="primary"
                onClick={() => {
                  if (!!userSubscriptions.find((sub) => sub.event_id === events[0].id && sub.status_id != 2)) {
                    alert("Você já está inscrito nesse evento!")
                    return
                  }
                  setSelectedDate(() => parseDate(events[0].starts_at));
                  handleOpen();
                }}
                sx={{ my: 2, cursor: "pointer" }}
              >
                Quero me pré-inscrever
              </Link>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Paper sx={{ mt: "20px" }}>
        <SectionTitle sx={{ p: "10px" }} title="Lista de eventos:" />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome do Evento</TableCell>
                <TableCell align="right">Data</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events &&
                events.filter(event => !userSubscriptions.filter(sub => sub.status_id === 2).find(sub => sub.event_id === event.id)).map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{`${parseDateLocale(
                      row.starts_at
                    )} até ${parseDateLocale(row.ends_at)}`}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          setSelectedDate(() => parseDate(row.starts_at));
                          setSelectedEvent(row)
                          handleOpen();
                        }}
                        variant="contained"
                        disabled={!!userSubscriptions.find((sub) => sub.event_id === row.id && sub.status_id != 2)}
                      >
                        Quero me pré-inscrever
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
