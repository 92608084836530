import React from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import {
  Box,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import BrandForm from "./BrandForm";
import UserForm from "./UserForm";
import ConfirmSignup from "./ConfirmSignup";
import { Link } from "react-router-dom";

import { ChevronLeft } from "@mui/icons-material";

const steps = [
  "Cadastro de Usuário",
  "Cadastro da sua Marca",
  "Confirmação do Cadastro",
];

const SuccessMessage = () => (
  <Box sx={{ bgcolor: "success.main", borderRadius: 1 }}>
    <Alert variant="filled" severity="success">
      Parabéns, seu CADASTRO no sistema da Feira do Bem foi realizado com sucesso!
      <br />
      A partir de agora, você já pode acessar sua conta e ficar à vontade para se PRÉ INSCREVER para a próxima edição do evento. <br/>
      OBS: Nossos emails podem cair na caixa de SPAM ou na Lixeira, fique atento a esse detalhe.
    </Alert>
  </Box>
);

const ErrorMessage = () => (
  <Box sx={{ bgcolor: "error.main", borderRadius: 1 }}>
    <Alert variant="filled" severity="error">
      Ocorreu algum erro na sua inscrição na Feira do Bem!
      <br />
      Recarregue a página e tente novamente!
      
      <br />
      Erro: { (window as any).signupMessage ? (window as any).signupMessage.message : ""}
    </Alert>
  </Box>
);

function getStepContent(step: number): JSX.Element {
  switch (step) {
    case 0:
      return <UserForm />;
    case 1:
      return <BrandForm />;
    case 2:
      return <ConfirmSignup />;
    default:
      return <Typography>Unknown step</Typography>;
  }
}

function Signup(props: any) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSignup = () => {
    try {
      if (activeStep === 2) {
        props.makeSignupRequest()
        setTimeout(() => {if( (window as any).signup === "error") setError(true); (window as any).signup = false}, 1000)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e: any) => {
    const formData = new FormData(e.target);

    const userData = Object.fromEntries(formData.entries());
    props.saveSignupData(userData);

    e.preventDefault();
    return handleNext();
  };

  return (
    <>
      <Container component="header" maxWidth="xs" sx={{ m: 3 }}>
        <Link to={"/"}>
          <Button variant="text">
            <ChevronLeft />
            Voltar
          </Button>
        </Link>
      </Container>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h6" align="center">
            Cadastro na Feira do Bem
          </Typography>

          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box component={"form"} onSubmit={handleSubmit}>
            {activeStep < 3 ? (
              getStepContent(activeStep)
            ) : (
              (error && <ErrorMessage />) || <SuccessMessage />
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && activeStep !== 3 && (
                <Button sx={{ mt: 3, ml: 1 }} onClick={handleBack}>
                  Voltar
                </Button>
              )}

              {activeStep < 3 ? (
                <Button
                  variant={"contained"}
                  sx={{ mt: 3, ml: 1 }}
                  type="submit"
                  onClick={handleSignup}
                >
                  Próximo
                </Button>
              ) : (
                <Link to="/login">
                  <Button sx={{ mt: 3, ml: 1 }}>
                    Voltar para a tela de login
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    signupData: state.signupData,
  };
}

export default connect(mapStateToProps, actions)(Signup);
