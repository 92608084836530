import * as React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";

import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleIcon from '@mui/icons-material/Article';
import EventIcon from "@mui/icons-material/Event";
import CollectionsIcon from "@mui/icons-material/Collections";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Link } from "react-router-dom";

export const MainListItems = ({ subscriptions }: { subscriptions: any }) => {

  const [preSubs, setPreSubs] = React.useState([])
  const [subs, setSubs] = React.useState([])

  React.useEffect(() => {
    setPreSubs(Array.isArray(subscriptions) && subscriptions.filter(x => x.status_id === 0))
    setSubs(Array.isArray(subscriptions) && subscriptions.filter(x => x.status_id === 1))
  }, [subscriptions])

  return (
    <React.Fragment>

      <Link to="events">
        <ListItemButton>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Calendário de Eventos" />
        </ListItemButton>
      </Link>

      {preSubs.length > 0 && (<Link to="pre-subscriptions">
        <ListItemButton>
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Pré Inscrições" />
        </ListItemButton>
      </Link>)}

      {subs.length > 0 && (<Link to="subscriptions">
        <ListItemButton>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary="Inscrições" />
        </ListItemButton>
      </Link>)}

      {subs.length > 0 && subs.filter(sub => sub.is_paid).length > 0 && (<Link to="documents">
        <ListItemButton>
          <ListItemIcon>
            <FileCopyIcon></FileCopyIcon>
          </ListItemIcon>
          <ListItemText primary="Documentos" />
        </ListItemButton>
      </Link>)}

      {subs.length > 0 && subs.filter(sub => sub.is_paid).length > 0 && (<Link to="receipts">
        <ListItemButton>
          <ListItemIcon>
            <ReceiptLongIcon></ReceiptLongIcon>
          </ListItemIcon>
          <ListItemText primary="Comprovantes" />
        </ListItemButton>
      </Link>)}

      {subs.length > 0 && subs.filter(sub => sub.is_paid).length > 0  && (<Link to="gallery">
      <ListItemButton>
        <ListItemIcon>
          <CollectionsIcon></CollectionsIcon>
        </ListItemIcon>
        <ListItemText primary="Uploads" />
      </ListItemButton>
    </Link>)}

    {subs.length > 0 && (<Link to="commissions">
      <ListItemButton>
        <ListItemIcon>
          <MonetizationOnIcon></MonetizationOnIcon>
        </ListItemIcon>
        <ListItemText primary="Comissões" />
      </ListItemButton>
    </Link>)}

    </React.Fragment>
  )
}

export const secondaryListItems = (
  <React.Fragment>
    {/*<ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Ultimos Registros" />
    </ListItemButton>*/}

  </React.Fragment>
);

//&& subs.filter(sub => sub.is_paid).length > 0