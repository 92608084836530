import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import Switch from "@mui/material/Switch";
import createCustomTheme from "./theme";

interface IThemeContainer {
  children: JSX.Element[] | JSX.Element;
}

export default function ThemeContainer({ children }: IThemeContainer) {
  const [themeMode, setThemeMode] = React.useState<any>(
    localStorage.getItem("mode") || "light"
  );

  const handleThemeChange = () => {
    if (themeMode === "light") {
      setThemeMode("dark");
    } else {
      setThemeMode("light");
    }
    localStorage.removeItem("mode");
    localStorage.setItem("mode", themeMode === "dark" ? "light" : "dark");
  };

  const theme = createCustomTheme(themeMode);

  return (
    <>
      <div className="change-theme-box">
        <LightModeIcon />
        <Switch
          checked={themeMode === "dark" ? true : false}
          onClick={handleThemeChange}
        />
        <DarkModeIcon />
      </div>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
}
