import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Grid, Paper } from "@mui/material";
import SectionTitle from "../../../ui-components/SectionTitle";
import { getAllPics, deleteGallery } from "../../../services/gallery.service";
import {ImageListItemBar, IconButton} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { getAllExpositors } from "../../../services/expositor.service";

export default function Gallery() {

  const [gallery, setGallery] = React.useState([]);
  const [expositors, setExpositors] = React.useState([]);

  React.useEffect(() => {
    getAllPics().then(x => {
      setGallery(x)
    })
  }, []);

  React.useEffect(() => {
    getAllExpositors()
      .then((x) => x.json())
      .then((x) => setExpositors(x));
  }, []);

  return (
    <Grid container spacing={3} my={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <SectionTitle title="Galeria de Imagens e Videos"/>
          <ImageList>
            {gallery && gallery.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={item.url}
                  alt={item.name}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={`Usuario: ${expositors.filter(x => x.id === item.user_id)[0]?.name}`}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.title}`}
                      onClick={(e) => {
                        if(window.confirm("Deseja apagar este arquivo? Essa operação pode ser irreversível")){
                          deleteGallery(item.id).then(() => {
                            getAllPics().then(x => {
                              setGallery(x)
                            })
                          })
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Paper>
      </Grid>
    </Grid>
  );
}
