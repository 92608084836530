import { getToken } from "./auth.service";

export const getPixCode = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/pix`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    method: "GET",
  }).then(x => {
    console.log(x);
    
    return x.json()
  });
};

export const editPixCode = (code) => {
  return fetch(`${process.env.REACT_APP_API_URL}/pix`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      pixcode: code
    }),
    method: "POST",
  }).then(x => x.json());
};

