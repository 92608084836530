import { getToken } from "./auth.service";

const getAllEvents = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/events`).then(x => x.json());
}

const updateEvent = (data: any, id: number) => {
    return fetch(`${process.env.REACT_APP_API_URL}/events/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "PATCH",
        body: JSON.stringify(data)
    })
}

const createEvent = (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}/events`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        },
        method: "POST",
        body: JSON.stringify(data)
    })
}

const deleteEvent = (id: number) => {

    return fetch(`${process.env.REACT_APP_API_URL}/events/${id}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getToken()}`
        }
    }).then(x => x.json());
}

export { getAllEvents, deleteEvent, updateEvent, createEvent }