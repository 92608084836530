import React from "react";
import { useAdminStore } from "../../context/admin-store";

interface DateCalculatorProps {
  startDate: Date;
  endDate: Date;
  eventData1: any;
  type?: string;
  eventTypes: any;
}

const DateCalculator: React.FC<DateCalculatorProps> = ({
  startDate,
  endDate,
  eventData1,
  type = "event",
  eventTypes
}) => {
  const calculateDaysDifference = (start: Date, end: Date): number => {
    const timeDifference = end.getTime() - start.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  };

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const eventData = type === "show" ? eventData1 : eventTypes.find(
    (events) => +events.id === +eventData1.event_data.eventType_id
  );

  const daysDifference = calculateDaysDifference(startDateObj, endDateObj);

  console.log(eventData1, eventData, type, eventTypes);

  return (
    <>
      {eventData && (
        <div className="teste">
          <table className="valuetable">
            <tr>
              <th colSpan={5}>Espaço Maior em Tenda - Tamanho: 1,94m de frente x 1,94 de profundidade</th>
            </tr>
            <tr>
              <th colSpan={5}>
                Contempla espaço em tenda, 01 cadeira e acesso à energia 220v

              </th>
            </tr>
            <tr>
              <td></td>
              <td>Inscrição</td>

              <td>Mesa Opcional</td>
              <td>Cadeira Extra Opcional</td>
            </tr>
            <tr>
              <td>1 dia</td>
              <td>
                R${" "}
                {eventData.event_data.exclusive.subscription_price_1_exclusive},00 + {eventData.event_data.exclusive.percentage_exclusive} % do faturamento bruto
              </td>

              <td>
                R$ {eventData.event_data.exclusive.table_price_exclusive_1},00
              </td>
              <td>
                R$ {eventData.event_data.exclusive.chair_price_exclusive_1},00
              </td>
            </tr>
            {daysDifference > 0 && (<tr>
              <td>2 dias</td>
              <td>
                R${" "}
                {eventData.event_data.exclusive.subscription_price_2_exclusive},00 + {eventData.event_data.exclusive.percentage_exclusive} % do faturamento bruto
              </td>

              <td>
                R$ {eventData.event_data.exclusive.table_price_exclusive_2},00
              </td>
              <td>
                R$ {eventData.event_data.exclusive.chair_price_exclusive_2},00
              </td>
            </tr>)}

            <tr>
              <th colSpan={5}>Espaço Menor/Compartilhado em Tenda - Tamanho: 0,85m de frente x 1,94 de profundidade</th>
            </tr>
            <tr>
              <th colSpan={5}>
                Contempla a tenda, 1 cadeira e acesso a energia 220v
              </th>
            </tr>
            <tr>
              <td></td>
              <td>Inscrição</td>

              <td>Mesa Opcional</td>
              <td>Cadeira Extra Opcional</td>
            </tr>
            <tr>
              <td>1 dia</td>
              <td>
                R$ {eventData.event_data.shared.subscription_price_1_shared},00 + {eventData.event_data.shared.percentage_shared} % do faturamento bruto
              </td>

              <td>R$ {eventData.event_data.shared.table_price_shared_1},00</td>
              <td>R$ {eventData.event_data.shared.chair_price_shared_1},00</td>
            </tr>
            {daysDifference > 0 && (<tr>
              <td>2 dias</td>
              <td>
                R$ {eventData.event_data.shared.subscription_price_2_shared},00 + {eventData.event_data.shared.percentage_shared} % do faturamento bruto
              </td>

              <td>R$ {eventData.event_data.shared.table_price_shared_2},00</td>
              <td>R$ {eventData.event_data.shared.chair_price_shared_2},00</td>
            </tr>)}

            <tr>
              <th colSpan={5}>Praça de Alimentação</th>
            </tr>
            <tr>
              <th colSpan={5}>Contempla espaço em tenda, tamanho 1,94mx1,94m, 01 cadeira e acesso à energia 220v
              </th>
            </tr>
            <tr>
              <td></td>
              <td>Inscrição</td>
            
              <td>Mesa Opcional</td>
              <td>Cadeira Extra Opcional</td>
            </tr>
            <tr>
              <td>1 dia</td>
              <td>
                R${" "}
                {eventData.event_data.foodcourt.subscription_price_1_foodcourt},00 + {eventData.event_data.foodcourt.percentage_foodcourt} % do faturamento bruto
              </td>
          
              <td>
                R$ {eventData.event_data.foodcourt.table_price_foodcourt_1},00
              </td>
              <td>
                R$ {eventData.event_data.foodcourt.chair_price_foodcourt_1},00
              </td>
            </tr>
            {daysDifference > 0 && (<tr>
              <td>2 dias</td>
              <td>
                R${" "}
                {eventData.event_data.foodcourt.subscription_price_2_foodcourt},00 + {eventData.event_data.foodcourt.percentage_foodcourt} % do faturamento bruto
              </td>
             
              <td>
                R$ {eventData.event_data.foodcourt.table_price_foodcourt_2},00
              </td>
              <td>
                R$ {eventData.event_data.foodcourt.chair_price_foodcourt_2},00
              </td>
            </tr>)}

            <tr>
              <th colSpan={5}>Trailer</th>
            </tr>
            <tr>
              <th colSpan={5}>Expositor deve levar seu próprio trailer</th>
            </tr>
            <tr>
              <td></td>
              <td>Inscrição</td>
            
              <td>Cadeira Extra Opcional</td>
            </tr>
            <tr>
              <td>1 dia</td>
              <td>
                R$ {eventData.event_data.trailer.subscription_price_1_trailer},00 + {eventData.event_data.trailer.percentage_trailer} % do faturamento bruto
              </td>
             
              <td>R$ {eventData.event_data.trailer.chair_price_trailer_1},00</td>
            </tr>
            {daysDifference > 0 && (
              <tr>
                <td>2 dias</td>
                <td>
                  R$ {eventData.event_data.trailer.subscription_price_2_trailer},00 + {eventData.event_data.trailer.percentage_trailer} % do faturamento bruto
                </td>
             
                <td>R$ {eventData.event_data.trailer.chair_price_trailer_2},00</td>
              </tr>
            )}

            <tr>
              <th colSpan={5}>Bike</th>
            </tr>
            <tr>
              <th colSpan={5}>Expositor deve levar sua bike</th>
            </tr>
            <tr>
              <td></td>
              <td>Inscrição</td>
              <td>Cadeira Extra Opcional</td>
            </tr>
            <tr>
              <td>1 dia</td>
              <td>R$ {eventData.event_data.bike.subscription_price_1_bike},00 + {eventData.event_data.bike.percentage_bike} % do faturamento bruto</td>

              <td>R$ {eventData.event_data.bike.chair_price_bike_1},00</td>
            </tr>
            {daysDifference > 0 && (
              <tr>
                <td>2 dias</td>
                <td>
                  R$ {eventData.event_data.bike.subscription_price_2_bike},00 + {eventData.event_data.bike.percentage_bike} % do faturamento
                </td>

                <td>R$ {eventData.event_data.bike.chair_price_bike_2},00</td>
              </tr>
            )}
          </table>
        </div>
      )}
    </>
  );
};

export default DateCalculator;
