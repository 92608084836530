import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./scss/index.scss";
import Root from "./root";
import Routes from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <Root>
      <Routes />
    </Root>
);

reportWebVitals();
