import React from "react";
import { Container, TextField, Button, Box, Typography, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import logo from "../assets/logo-white.png";
import recoverPassword from "../services/recover-password.service";

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    recoverPassword(email).then((x) => {
      setOpen(true);
    });
  };



  return (
    <>
      <Container component="header" maxWidth="xs" sx={{ m: 3 }}>
        <Link to={"/"}>
          <Button variant="text">
            <ChevronLeft />
            Voltar
          </Button>
        </Link>
      </Container>
      <Container
        component={"form"}
        onSubmit={handleSubmit}
        maxWidth={"xs"}
        sx={{ height: "80vh", display: "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            src={logo}
            sx={{
              width: "200px",
              backgroundColor: "#a8518e",
              borderRadius: "50%",
              marginBottom: "1rem",
            }}
          />
          <Typography>
            Digite o seu email e enviaremos um link para você redefinir sua
            senha.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="email"
            label="Endereço de Email"
            type="email"
            id="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Enviar link de recuperação
          </Button>
        </Box>
        <Snackbar
          open={open}
          autoHideDuration={10000}
          onClose={() => setOpen(false)}
          message="Email enviado com sucesso! Cheque sua caixa de SPAM caso nãa consigo encontrar seu email! Fique atento!"
        />
      </Container>
    </>
  );
}
