import create from "zustand";
import { getAllCategories } from "../services/category.service";

export const useCategory = create(set => ({
  categories: [],
  fetch: async () => {
    const categories = await getAllCategories()
    set(
      {
        categories: categories
      }
    )
  }
}))