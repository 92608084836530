import React from "react";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DateCalculator from "../../../priceTable";
import { useAdminStore } from "../../../../context/admin-store";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  overflow: "scroll",
  height: "80vh",
  p: 4,
};



export default function ViewButton({
  id,
  updateEvents,
  event,
}: {
  id: number;
  updateEvents: Function;
  event: any;
}) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const eventTypes = useAdminStore((state) => state.eventTypes);
  

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Vendo todas as informações do tipo de Evento :
          </Typography>
          <>
            <p>ID: {event.id}</p>
            <p>Nome: {event.event_data.name}</p>

            <DateCalculator startDate={new Date("01/02/2023")} endDate={new Date("01/03/2023")} eventData1={event} type={"show"} eventTypes={eventTypes}></DateCalculator>

          </>
        </Box>
      </Modal>
      <IconButton title="Pressione 2 vezes para ver mais" onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
    </>
  );
}
