function parseDate(date: any) { 
    const fixdate = typeof date === "string" ? date.replace(/-/g, '\/').replace(/T.+/, '') : date
    const newDate = new Date(fixdate);  
    const parsedDate = `${(newDate.getDate()).toString().padStart(2, "0")}/${(newDate.getMonth() + 1).toString().padStart(2, "0")}/${newDate.getFullYear()}`
    return parsedDate;
};

function parseDateHifen(date: any) {
    const fixdate = typeof date === "string" ? date.replace(/-/g, '\/').replace(/T.+/, '') : date
    const newDate = new Date(fixdate); 
    const parsedDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, "0")}-${(newDate.getDate()).toString().padStart(2, "0")}`
    return parsedDate;
};

function parseDateLocale(date: any) {
    const fixdate = typeof date === "string" ? date.replace(/-/g, '\/').replace(/T.+/, '') : date
    const newDate = new Date(fixdate); 
    const options: any = { weekday: "long", month: "long", day: "numeric" };
    return new Date(newDate).toLocaleDateString("pt-BR", options);
};

export {parseDate, parseDateLocale, parseDateHifen};