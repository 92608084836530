import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { TextField, Select, MenuItem, Grid } from "@mui/material";
import { parseDate, parseDateHifen } from "../../../utils/parseDate";
import table from "./table2.png";
import { getUserInfo } from "../../../services/auth.service";
import { createSubscription } from "../../../services/expositor/subscriptions.service";
import { intervalToDuration, isWithinInterval } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import { ModalComponent, useModal } from "../../../ui-components/Modal";
import { getUserOnly } from "../../../services/expositor/subscriptions.service";
import DateCalculator from "../../priceTable";
import { getAllEvents } from "../../../services/event-type.service";

const subname = {
  shared: "Espaço Menor/Compartilhado em Tenda",
  exclusive: "Espaço Maior em Tenda",
  foodcourt: "Praça de Alimentação",
  trailer: "Trailer",
  bike: "Bike",
};

function getSubscriptionPrice(
  spaceType,
  dayCount = 1,
  extraChairs = 0,
  extraTables = 0
) {
  if (spaceType === "exclusive") {
    return dayCount === 1
      ? 495 + +extraChairs * 10 + +extraTables * 50
      : 795 + extraChairs * 20 + extraTables * 60;
  } else if (spaceType === "shared") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  } else if (spaceType === "foodcourt") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  } else if (spaceType === "trailer") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  } else if (spaceType === "bike") {
    return dayCount === 1
      ? 325 + extraChairs * 10 + extraTables * 25
      : 545 + extraChairs * 20 + extraTables * 30;
  }
}

function getSubscriptionPriceDinamic(
  spaceType,
  dayCount = 1,
  extraChairs = 0,
  extraTables = 0,
  sharedSubPrice1,
  sharedSubPrice2,
  sharedChairPrice1,
  sharedChairPrice2,
  sharedTablePrice1,
  sharedTablePrice2,
  exclusiveSubPrice1,
  exclusiveSubPrice2,
  exclusiveChairPrice1,
  exclusiveChairPrice2,
  exclusiveTablePrice1,
  exclusiveTablePrice2,
  eventData
) {
  const d = eventData.event_data;
  if (spaceType === "exclusive") {
    return dayCount === 1
      ? exclusiveSubPrice1 +
          +extraChairs * exclusiveChairPrice1 +
          +extraTables * exclusiveTablePrice1
      : exclusiveSubPrice2 +
          extraChairs * exclusiveChairPrice2 +
          extraTables * exclusiveTablePrice2;
  } else if (spaceType === "shared") {
    return dayCount === 1
      ? sharedSubPrice1 +
          extraChairs * sharedChairPrice1 +
          extraTables * sharedTablePrice1
      : sharedSubPrice2 +
          extraChairs * sharedChairPrice2 +
          extraTables * sharedTablePrice2;
  } else if (spaceType === "foodcourt") {
    return dayCount === 1
      ? d.foodcourt.subscription_price_1_foodcourt +
          extraChairs * d.foodcourt.chair_price_foodcourt_1 +
          extraTables * d.foodcourt.table_price_foodcourt_1
      : d.foodcourt.subscription_price_2_foodcourt +
          extraChairs * d.foodcourt.chair_price_foodcourt_2 +
          extraTables * d.foodcourt.table_price_foodcourt_2;
  } else if (spaceType === "trailer") {
    console.log(spaceType, dayCount);
    return dayCount === 1
      ? d.trailer.subscription_price_1_trailer +
          extraChairs * d.trailer.chair_price_trailer_1
      : d.trailer.subscription_price_2_trailer +
          extraChairs * d.trailer.chair_price_trailer_2;
  } else if (spaceType === "bike") {
    return dayCount === 1
      ? d.bike.subscription_price_1_bike +
          extraChairs * d.bike.chair_price_bike_1
      : d.bike.subscription_price_2_bike +
          extraChairs * d.bike.chair_price_bike_2;
  }
}

const PriceTable = ({ eventData, eventTypes }) => {
  return (
    <DateCalculator
      eventTypes={eventTypes}
      startDate={eventData.starts_at}
      endDate={eventData.ends_at}
      eventData1={eventData}
    ></DateCalculator>
  );
};

//TOdo get the event and create subscription logic
export default function CreateSubscriptionForm({
  date,
  events,
  handleClose,
  event,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [userSubs, setUserSubs] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setUserSubs(await getUserOnly());
      setEventTypes(await getAllEvents());
    })();
  }, []);

  const chosenevent = event;

  React.useEffect(() => {
    setFormData((state) => ({
      ...state,
      user_id: getUserInfo().id,
      event_id: chosenevent.id,
      start_date: parseDateHifen(event.starts_at),
      end_date: parseDateHifen(event.starts_at),
      extra_table: 0,
      extra_chair: 0,
      space_type: userSubs.filter((x) => x.space_type)[0]?.space_type || "shared",
      table_quantity: 0,
      chair_quantity: 0,
      notes: userSubs.filter((x) => x.notes)[0]?.notes || "",
      items: userSubs.filter((x) => x.items)[0]?.items || "",
    }));
  }, [userSubs]);

  const steps = [
    {
      label: "Informaçoes sobre a pré-inscrição e tabela de preços",
      description: (
        <>
          { event && 
            <>
            {event.event_data && eventTypes.length > 0 ? (
            <PriceTable eventData={event} eventTypes={eventTypes}></PriceTable>
          ) : (
            <>{!event.event_data && !!eventTypes.length && <img src={table} alt="" />}</>
          )}
            </>
          }
        </>
      ),
    },
    {
      label: "Selecionar dia/dias",
      description: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ my: 2 }}
            id="start_date"
            name="start_date"
            label="Data de inicio da participacao"
            type={"date"}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                start_date: e.target.value,
              }));
            }}
            defaultValue={formData?.start_date || parseDateHifen(date)}
            autoComplete="start_date"
            variant="standard"
            InputProps={{
              inputProps: {
                min: parseDateHifen(chosenevent.starts_at),
                max: parseDateHifen(chosenevent.ends_at),
              },
            }}
            required
          />
          <TextField
            sx={{ my: 2 }}
            id="end_date"
            name="end_date"
            label="Data de Fim da participacao"
            type={"date"}
            defaultValue={formData?.end_date || parseDateHifen(date)}
            onChange={(e) => {
              setFormData((state) => ({ ...state, end_date: e.target.value }));
            }}
            autoComplete="start_date"
            variant="standard"
            InputProps={{
              inputProps: {
                min: parseDateHifen(chosenevent.starts_at),
                max: parseDateHifen(chosenevent.ends_at),
              },
            }}
            required
          />
        </div>
      ),
    },
    {
      label: "Escolher tipo de espaço do evento",
      description: (
        <>
          <Select
            required
            labelId="space_type"
            id="space_type"
            name="space_type"
            defaultValue={
              userSubs.filter((x) => x.space_type)[0]?.space_type || "shared"
            }
            autoFocus
            onFocus={(e) => {
              const data = e.target.nextSibling.value;
              setFormData((state) => ({
                ...state,
                space_type: data,
              }));
            }}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                space_type: e.target.value,
              }));
            }}
            fullWidth
          >
            <MenuItem key={1} value={"shared"}>
              Espaço Menor/Compartilhado em Tenda
            </MenuItem>
            <MenuItem key={2} value={"exclusive"}>
              Espaço Maior em Tenda
            </MenuItem>
            <MenuItem key={2} value={"foodcourt"}>
              Praça de Alimentação
            </MenuItem>
            <MenuItem key={2} value={"trailer"}>
              Trailer
            </MenuItem>
            <MenuItem key={2} value={"bike"}>
              Bicicleta
            </MenuItem>
          </Select>
        </>
      ),
    },
    {
      label: "Deseja cadeira extra ao custo de R$10,00/dia?",
      description: (
        <>
          <Select
            required
            labelId="extra_chair"
            id="extra_chair"
            defaultValue={Boolean(
              userSubs.filter((x) => x.extra_chair)[0]?.extra_chair
            )}
            name="extra_chair"
            autoFocus
            onFocus={(e) => {
              setFormData((state) => ({
                ...state,
                extra_chair: +(e.target.nextSibling.value === "true"),
              }));
            }}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                extra_chair: +e.target.value,
              }));
            }}
            fullWidth
          >
            <MenuItem key={1} value={true}>
              Sim
            </MenuItem>
            <MenuItem key={2} value={false}>
              Não
            </MenuItem>
          </Select>

          {(!!formData.extra_chair ||
            !!userSubs.filter((x) => x.extra_chair)[0]?.extra_chair) && (
            <TextField
              sx={{ my: 2 }}
              id="chair_quantity"
              name="chair_quantity"
              label="Quantas cadeiras?"
              defaultValue={0}
              onChange={(e) => {
                setFormData((state) => ({
                  ...state,
                  chair_quantity: +e.target.value,
                }));
              }}
              variant="standard"
              fullWidth
            />
          )}
        </>
      ),
    },
    {
      label: `Deseja mesa?
      Mesa Espaço Maior: 1,70m x 0,70 - 
      Mesa Espaço Menor/Compartilhado: 0,85m x 0,70.       
       `,
      description: (
        <>
          <Select
            required
            labelId="extra_table"
            id="extra_table"
            name="extra_table"
            defaultValue={Boolean(
              userSubs.filter((x) => x.extra_table)[0]?.extra_table
            )}
            autoFocus
            onFocus={(e) => {
              setFormData((state) => ({
                ...state,
                extra_table: +(e.target.nextSibling.value === "true"),
              }));
            }}
            onChange={(e) => {
              setFormData((state) => ({
                ...state,
                extra_table: +e.target.value,
              }));
            }}
            fullWidth
          >
            <MenuItem key={1} value={true}>
              Sim
            </MenuItem>
            <MenuItem key={2} value={false}>
              Não
            </MenuItem>
          </Select>

          {(!!formData.extra_table ||
            Boolean(userSubs.filter((x) => x.extra_table)[0]?.extra_table)) && (
            <TextField
              sx={{ my: 2 }}
              id="table_quantity"
              name="table_quantity"
              label="Quantas mesas?"
              defaultValue={0}
              onChange={(e) => {
                setFormData((state) => ({
                  ...state,
                  table_quantity: +e.target.value,
                }));
              }}
              variant="standard"
              fullWidth
            />
          )}
        </>
      ),
    },
    {
      label:
        "Descreva TODOS os itens que você pretende levar para venda na Feira do Bem (lembrando que só são permitidos itens autorais - não é autorizado a revenda e que a Curadoria pode aprovar parcialmente os itens descritos por você abaixo)",
      description: (
        <>
          <TextField
            sx={{ my: 2 }}
            id="items"
            name="items"
            label="Itens"
            placeholder="Liste um a um os itens que
            você produz e pretende levar
            na Feira do Bem."
            onChange={(e) => {
              setFormData((state) => ({ ...state, items: e.target.value }));
            }}
            autoFocus
            onFocus={(e) => {
              setFormData((state) => ({
                ...state,
                items: e.target.value,
              }));
            }}
            autoComplete="items"
            variant="standard"
            defaultValue={userSubs.filter((x) => x.items)[0]?.items}
            rows={4}
            multiline
            fullWidth
            required
          />
        </>
      ),
    },
    {
      label: "Alguma observação?",
      description: (
        <>
          <TextField
            sx={{ my: 2 }}
            id="notes"
            name="notes"
            label="Notas"
            onChange={(e) => {
              setFormData((state) => ({ ...state, notes: e.target.value }));
            }}
            autoFocus
            onFocus={(e) => {
              setFormData((state) => ({
                ...state,
                notes: e.target.value,
              }));
            }}
            autoComplete="notes"
            variant="standard"
            defaultValue={userSubs.filter((x) => x.notes)[0]?.notes}
            rows={4}
            multiline
            fullWidth
            required
          />
        </>
      ),
    },
  ];

  const handleNext = (e) => {
    if (formData.space_type && formData.start_date && formData.end_date) {
      const data = eventTypes.find(
        (evente) => +evente.id === +event.event_data.eventType_id
      );

      const isStartDateValid = isWithinInterval(new Date(formData.start_date), {
        start: new Date(chosenevent.starts_at),
        end: new Date(chosenevent.ends_at),
      });

      const isEndDateValid = isWithinInterval(new Date(formData.end_date), {
        start: new Date(chosenevent.starts_at),
        end: new Date(chosenevent.ends_at),
      });

      if ((!isStartDateValid || !isEndDateValid) && activeStep === 1) {
        alert("Data inválida!");
        return;
      }

      if(new Date(formData.start_date) > new Date(formData.end_date)) {
        alert("A data de início não pode ser depois da data final!")
        return
      }

      const amount = event.event_data
        ? getSubscriptionPriceDinamic(
            formData.space_type,
            intervalToDuration({
              start: new Date(formData.start_date),
              end: new Date(formData.end_date),
            }).days + 1,
            formData.extra_chair ? formData.chair_quantity : 0,
            formData.extra_table ? formData.table_quantity : 0,
            data.event_data.shared.subscription_price_1_shared,
            data.event_data.shared.subscription_price_2_shared,
            data.event_data.shared.chair_price_shared_1,
            data.event_data.shared.chair_price_shared_2,
            data.event_data.shared.table_price_shared_1,
            data.event_data.shared.table_price_shared_2,
            data.event_data.exclusive.subscription_price_1_exclusive,
            data.event_data.exclusive.subscription_price_2_exclusive,
            data.event_data.exclusive.chair_price_exclusive_1,
            data.event_data.exclusive.chair_price_exclusive_2,
            data.event_data.exclusive.table_price_exclusive_1,
            data.event_data.exclusive.table_price_exclusive_2,
            data
          )
        : getSubscriptionPrice(
            formData.space_type,
            intervalToDuration({
              start: new Date(formData.start_date),
              end: new Date(formData.end_date),
            }).days + 1,
            formData.chair_quantity,
            formData.table_quantity
          );
      setFormData((state) => ({ ...state, amount: amount }));

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if(formData.start_date && formData.end_date) {
      console.log("a", (formData.start_date && formData.end_date));
      const isStartDateValid = isWithinInterval(new Date(formData.start_date), {
        start: new Date(chosenevent.starts_at),
        end: new Date(chosenevent.ends_at),
      });

      const isEndDateValid = isWithinInterval(new Date(formData.end_date), {
        start: new Date(chosenevent.starts_at),
        end: new Date(chosenevent.ends_at),
      });
      
      if ((!isStartDateValid || !isEndDateValid) && activeStep === 1) {
        alert("Data inválida!");
        return;
      }

      if(new Date(formData.start_date) > new Date(formData.end_date)) {
        alert("A data de início não pode ser depois da data final!")
        return
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    await createSubscription(formData);
  };

  const { open, handleOpen, handleClose: hClose } = useModal();

  const selectMesa = {
    exclusive: "Mesa de 1,70m x 0,70",
    shared: "Mesa de 0,85m x 0,70"
  }

  return (
    <Box className="teste">
      {activeStep !== 0 && activeStep !== steps.length && (
        <>
          <Button variant="contained" onClick={handleOpen}>
            {" "}
            <InfoIcon />
            &nbsp; Ver tabela de preços novamente
          </Button>
          <ModalComponent open={open} handleClose={hClose}>
            {event.event_data && eventTypes.length > 0 ? (
              <PriceTable
                eventData={event}
                eventTypes={eventTypes}
              ></PriceTable>
            ) : (
              <img src={table} alt="" />
            )}
            <p style={{ margin: "10px" }}>
              Clique em qualquer lugar para fechar a imagem
            </p>
          </ModalComponent>
        </>
      )}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finalizar" : "Continuar"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Voltar
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography my={2}>
            Confirme suas escolhas para criar sua pré-inscrição:
          </Typography>
          <Grid>
            <Typography>
              <span style={{ color: "#a8518e" }}>
                Data de início da sua participação:{" "}
              </span>
              {parseDate(formData.start_date)}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>
                Data de fim da sua participação:{" "}
              </span>
              {parseDate(formData.end_date)}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Tipo de espaço: </span>
              {subname[formData.space_type]}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Mesa: </span>
              {formData.extra_table ? `Sim${(", " + selectMesa[formData.space_type]) || ""}` : `Não`}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Quantas mesas: </span>
              {formData.table_quantity || 0}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Cadeira Extra: </span>
              {formData.extra_chair ? "Sim" : "Não"}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Quantas cadeiras: </span>
              {formData.chair_quantity || 0}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Evento: </span>
              {chosenevent.name}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Itens: </span>
              {formData.items}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Observação: </span>
              {formData.notes}
            </Typography>
            <Typography>
              <span style={{ color: "#a8518e" }}>Valor: </span> R${" "}
              {formData.amount},00 + 10% do faturamento bruto
            </Typography>
          </Grid>

          <Grid flex>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
              sx={{ mt: 1, mr: 1 }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                handleSubmit().then(() => {
                  alert("Pré-inscrição criada com sucesso!");
                  handleClose();
                  window.location.reload();
                });
              }}
              sx={{ mt: 1, mr: 1 }}
            >
              Quero me pré-inscrever
            </Button>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}
