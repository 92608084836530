import { getToken } from "./auth.service";

export const getAllPics = async () => {
  return await(await fetch(`${process.env.REACT_APP_API_URL}/gallery`)).json();
}

export const deleteGallery = async (id) => {
  return await (await fetch(`${process.env.REACT_APP_API_URL}/gallery/${id}`, {
    method: "DELETE",
    headers: {
      "Authorization" : `Bearer ${getToken()}`
    }
  })).json()
}

